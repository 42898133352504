import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";

export default function Notification ({show,notification,setShow,classname}) {



    return (
    <>
          <ToastContainer
        className={`p-3`}
        position={"top-end"}
        style={{ zIndex: 9999999999999999 }}


      >
        <Toast
            onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className={classname}
        >
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Action Notification</strong>
            <small style={{ fontVariant: "small-caps" }}>kaleida</small>
          </Toast.Header>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>)
}