import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
  Offcanvas,
  Badge,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Clipboard2Check,
  Clipboard2X,
  Discord,
  Eye,
  FiletypeAi,
  Percent,
} from "react-bootstrap-icons";
import Line from "./Line";

export default function ReviewAppResponses({
  application,
  setReviewApp,
  reviewApp,
  directory,
  getData,
  orgData,
  setShowToast,
  setToastBody,
}) {
  const spriteSize = "20px";
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setReviewApp();
    setShowSidebar(false);
  };
  const handleShow = () => setShow(true);
  const [responses, setResponses] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [reviewResponse, setReviewResponse] = useState();
  const [reviewUser, setReviewUser] = useState();
  const [reviewUserDiscord,setReviewUserDiscord] = useState();

  useEffect(() => {
    if (!reviewApp) return;
    console.log("review app", reviewApp);
    setShow(true);
  }, [reviewApp]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.body}
    </Tooltip>
  );

  useEffect(() => {
    console.log("RE-RENDERED!!!!");
    if (!orgData) return;
    // set reviewapp
    if (!reviewApp) return;
    setReviewApp(orgData.applications[reviewApp.applicationMetaData.name]);
  }, [orgData]);

  const requestAiAnalysis = (applicationName, user, division) => {
    console.log("ai analysis");
    const config = {
      method: "post",
      url: document.MAIN_URL + "/gptReviewApplication",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("key"),
      },
      data: {
        applicationName: applicationName,
        directory,
        responseId: user,
        division: division,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          console.log("getting data?");
          getData();
        }
        if (res.data.message & (typeof res.data.message === "string")) {
          setToastBody(res.data.message);
          setShowToast(true);
        }
      })
      .catch(console.log);
  };

  const viewResponse = (response) => {
    setReviewUser();
    setReviewUserDiscord()
    console.log("view response");
    setShowSidebar(true);
    // we need to set the review response to a object mapped with the correct answers, and the user's answers
    const responseObject = {};
    Object.keys(response.answers).map((question) => {
      const options = reviewApp.applicationData[Number(question)].options;
      const typeColor =
        reviewApp.applicationData[Number(question)].type === "color";
      responseObject[question] = {
        question: reviewApp.applicationData[Number(question)].label,
        correctAnswer: reviewApp.applicationData[Number(question)].idealAnswer
          ? reviewApp.applicationData[Number(question)].idealAnswer
          : reviewApp.applicationData[Number(question)].correctOptions
          ? reviewApp.applicationData[Number(question)].correctOptions
          : "none", // sets correct answer for different input types (color, radio, ...)
        userAnswer: response.answers[question],
        options,
        typeColor,
      };
    });
    setReviewResponse(responseObject);
    console.log('response',response)
    const config = {
      method: "post",
      url: document.MAIN_URL + "/robloxMemberExists",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("key"),
      },
      data: {
        id : response.user.uid
      }
    }
    axios(config).then(res => {
      if (res.notFound) return
        console.log(res.data)
        setReviewUser(res.data)
      
    }).catch(console.log)
  };

  const deleteResponse = (response) => {
    console.log("delete response");
    const config = {
      method: "post",
      url: document.MAIN_URL + "/modifyResponse",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("key"),
      },
      data: {
        action: "delete",
        applicationName: reviewApp.applicationMetaData.name,
        directory,
        responseId: response,
        division: reviewApp.applicationMetaData.division,
      },
    };

    axios(config)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          console.log("getting data?");
          getData();
          setToastBody("Response deleted successfully");
          setShowToast(true);
        }
        if (res.data.message & (typeof res.data.message === "string")) {
          setToastBody(res.data.message);
          setShowToast(true);
        }
        if (!res.data.success) {
          setToastBody("Something went wrong while denying the response");
          setShowToast(true);
        }
      })
      .catch(console.log);
  };

  const acceptResponse = (response) => {
    const config = {
      method: "post",
      url: document.MAIN_URL + "/modifyResponse",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("key"),
      },
      data: {
        action: "accept",
        applicationName: reviewApp.applicationMetaData.name,
        directory,
        responseId: response,
        division: reviewApp.applicationMetaData.division,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          getData();
          setToastBody("Response accepted successfully");
          setShowToast(true);
          getData();
        }
        if (res.data.message && typeof res.data.message === "string") {
          setToastBody(res.data.message);
          setShowToast(true);
        }
        if (!res.data.success && !res.data.message) {
          setToastBody("Something went wrong while accepting the response");
          setShowToast(true);
        }
      })
      .catch(console.log);
  };

  const requestDiscordData = () => {
    const discordId = reviewUser.userData.discordId
    const config = {
      method: "post",
      url: document.MAIN_URL + "/queryMembers",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("key"),
      },
      data: {
        queryTerm: 'discord',
       query: discordId
      }
    }
    axios(config).then(res => {
      console.log('discordData',res.data)
      setReviewUserDiscord(res.data)
    }).catch(console.log)
  }



  return (
    <>
      <Modal show={show} onHide={handleClose} centered reviewApp>
        <Modal.Header closeButton>
          <Modal.Title>Responses</Modal.Title>
        </Modal.Header>
        <Modal.Body className="square">
          <Stack direction="horizontal" gap={3}>
            <small className="spec-disp white mono">
              accept - <Clipboard2Check /> | deny - <Clipboard2X /> | ai
              analysis - <FiletypeAi /> | review - <Eye />
            </small>
          </Stack>
          <br />
          {reviewApp && orgData ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>user</th>
                  <th>
                    <Percent />
                  </th>
                  <th>actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  // responses are an object
                  Object.keys(reviewApp.responses)?.map((user) => {
                    const response = reviewApp.responses[user];
                    return (
                      <tr>
                        <td>{response.user.name.toLowerCase()}</td>
                        <td>{response.score ? response.score : "none"}</td>
                        <td>
                          {" "}
                          <Clipboard2Check
                            size={spriteSize}
                            className="accept"
                            onClick={() => {
                              acceptResponse(user);
                            }}
                          />{" "}
                          <Clipboard2X
                            size={spriteSize}
                            className="deny"
                            onClick={() => {
                              deleteResponse(user);
                            }}
                          />{" "}
                          <FiletypeAi
                            className="ai"
                            size={spriteSize}
                            onClick={() => {
                              requestAiAnalysis(
                                reviewApp.applicationMetaData.name,
                                user,
                                reviewApp.applicationMetaData.division
                              );
                            }}
                          />{" "}
                          <Eye
                            className="review"
                            size={spriteSize}
                            onClick={() => viewResponse(response)}
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          ) : null}
        </Modal.Body>
      </Modal>
      <Offcanvas
        className="review-app"
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        style={{ zIndex: 500000, backdropFilter: "blur(500px)" }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>User Data</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack direction="vertical" gap={1}>
            {!reviewUser ? <>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner></> : 
            <>

            {!reviewUserDiscord ? <Discord className="ai" onClick={requestDiscordData}/> :
             <>
              <span className="white smallcaps mono spec-disp">discord-data</span>
              <Image src={reviewUserDiscord?.avatarURL} roundedCircle className="query-image img-fluid" small/>
              <span className="white smallcaps mono spec-disp">username {reviewUserDiscord?.username}</span>
              <span className="white smallcaps mono spec-disp">discriminator {reviewUserDiscord?.discriminator}</span>
              <span className="white smallcaps mono spec-disp">id {reviewUserDiscord?.id}</span>
              <span className="white smallcaps mono spec-disp">bot {reviewUserDiscord?.bot ? "true" : "false"}</span>
              <span className="white smallcaps mono spec-disp">system {reviewUserDiscord?.system ? "true" : "false"}</span>
               {/* created timestamp */}
                <span className="white smallcaps mono spec-disp">created {new Date(reviewUserDiscord?.createdTimestamp).toLocaleString()}</span>
                {/* roles */}
                <span className="white smallcaps mono spec-disp">roles ˇ</span>
                {reviewUserDiscord?.roles?.map(role => {
                  return <span className="white smallcaps mono spec-disp">{role}</span>
                })}
              <Line />
            </>} 

            <span className="white smallcaps mono spec-disp">discord-ID: {reviewUser?.userData?.discordId}</span>
            <span className="white smallcaps mono spec-disp">rbx-ID: {reviewUser?.userData?.uid}</span>
            <span className="white smallcaps mono spec-disp">cached-name: {reviewUser?.userData?.name}</span>
            <span className="white smallcaps mono spec-disp">user-divisions ˇ</span>
            {reviewUser?.userData?.divisions?.map(division => {
              return <span className="white smallcaps mono spec-disp">{division}</span>
            })
            }
            </>
            }
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        className="review-app"
        show={showSidebar}
        onHide={() => setShowSidebar(false)}
        style={{ zIndex: 500000, backdropFilter: "blur(500px)" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Response</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {reviewResponse ? (
            <>
              <Stack direction="vertical" gap={3} className="scroll">
                {Object.keys(reviewResponse).map((question) => {
                  const response = reviewResponse[question];
                  return (
                    <Stack direction="vertical" gap={1}>
                      <span className="white smallcaps mono spec-disp">
                        Q: {response.question}
                      </span>
                      <span className="white smallcaps mono spec-disp">
                        correct:{" "}
                        {typeof response.correctAnswer !== "object" ? (
                          response.correctAnswer
                        ) : (
                          <Stack direction="vertical" gap={1}>
                            {Object.values(response.correctAnswer).map(
                              (option, i) =>
                                option === true && (
                                  <span>
                                    {i + 1}. {response.options[i]}
                                  </span>
                                )
                            )}
                          </Stack>
                        )}
                      </span>
                      <span className="white smallcaps mono spec-disp">
                        user:
                        {response.options && response.typeColor !== true ? (
                          <>
                            {" "}
                            <br />
                            {` ${response.userAnswer + 1}. ${
                              response?.options[response.userAnswer]
                            }`}
                          </>
                        ) : (
                          !response.typeColor && response.userAnswer
                        )}
                        {response.typeColor === true ? (
                          <>
                            <div
                              color={response.userAnswer}
                              style={{
                                backgroundColor: `${response.userAnswer}`,
                              }}
                            >
                              {response.userAnswer}
                            </div>
                          </>
                        ) : null}
                      </span>
                      <br />
                    </Stack>
                  );
                })}
              </Stack>
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
