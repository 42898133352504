import { useEffect } from "react";
import { Image, Card, ListGroup } from "react-bootstrap";
import StylizedBG from "./StylizedBG";

export default function DiscordUserCard(props) {
  const { userData, handleAdd } = props;
  const { id, avatar, username, discriminator } = userData;
  useEffect(() => {
    console.log(userData);
  }, []);
  return (
    <>
      <Card style={{ width: "18rem" }}>
      
        <Card.Body>
          <Card.Title>{id}</Card.Title>
          <Image
            src={`https://cdn.discordapp.com/avatars/${id}/${avatar}.png?size=256`}
            responsive={true}
            rounded
            style={{ height: "125px" }}
            fluid
          />
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <span className="find-user-property">handle</span>{" "}
            <span className="find-user-divider"> : : </span>
            <span className="find-user-property-1">{username}</span>
            <span className="find-user-property-2">{discriminator !== '0' ? "#" : ""}</span>
            <span className="find-user-property-3">{discriminator !== '0' ? discriminator : ""}</span>{" "}
            <span className="find-user-property-1">/ {userData.nickname ? userData.nickname: null}</span>
          </ListGroup.Item>
          {/* <ListGroup.Item><span className="find-user-property-1">verified</span> <span className="find-user-divider"> : :</span> {hasVerifiedBadge ? "true" : "false"}</ListGroup.Item>
                    <ListGroup.Item><span className="find-user-property-2">uid</span> <span className="find-user-divider"> : :</span> {id}</ListGroup.Item>
                    <ListGroup.Item><span className="find-user-property-3">previous usernames</span> <span className="find-user-divider"> : : </span>
                      {previousUsernames.map((userName) =>   <>  {userName} <span className="find-user-divider"> | </span></>)
                      } </ListGroup.Item> */}
        </ListGroup>
        <Card.Body>
          <button
            className="button add-user"
            onClick={(e) => handleAdd(userData)}
          >
            create profile
          </button>
        </Card.Body>
      </Card>
    </>
  );
}

// previousUsernames.map((userName) =>   <>  {userName} <span className="find-user-divider"> | </span></>)
