import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Stack,
} from "react-bootstrap";
import { AuthContext } from "../App";
import { useContext, useEffect, useState } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { List } from "react-bootstrap-icons";
import DivisionTab from "../components/DivisionTab";
import Line from "../components/Line";
import StylizedBG from "../components/StylizedBG";

export default function ManageMembersDash() {
  const [formData, setFormData] = useState({});
  const [hide, setHide] = useState(false);
  const [userData, setUserData] = useState({});
  const [
    _,
    setAuthKey,
    authed,
    setAuthed,
    timeCreated,
    setTimeCreated,
    modalShow,
    setModalShow,
    modalData,
    setModalData,
  ] = useContext(AuthContext);
  const [currentModal, setCurrentModal] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const divisions = [
    "Security",
    "Research",
    "Hagilgulim",
    "Safety & Regulation",
    "External Affairs",
    "Logistics",
  ];
  const authKey = localStorage.getItem("key");

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  useEffect(() => {
    const config = {
      method: "GET",
      url: document.MAIN_URL+`/getSelf`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
    };
    axios(config).then((res) => {
      if (res.data.userData) {
        console.log(res.data);
        setUserData(res.data);
      }
    });
  }, []);

  return (
 <StylizedBG>
      
      <Tab.Container id="list-group-tabs-example " defaultActiveKey="#link1">
        <Container className="member-manage ">
          <br />
          <Row>
            <Col sm={hide ? -5 : 2}>
            <Button   onClick={e=>{setHide(oldHide=>!oldHide)}}>{hide? 'show divisions' :'hide divisions'}</Button>
            <Line/>
              {hide ? null : (
                <>
    
                  <h3>Divisions</h3>
                  <Stack direction="horizontal">
                    <ListGroup variant="flush">
                      {userData.divisions ? (
                        Object.keys(userData.divisions).map((division) => {
                          return (
                            <ListGroup.Item action href={`#${division}`} className="blur-bg backblur">
                              {division}
                            </ListGroup.Item>
                          );
                        })
                      ) : (
                        <ListGroup.Item>
                          Loading..
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Stack>
                </>
              )}
              
            </Col>
            <Col  >
              {" "}
              <Tab.Content >
                {userData.divisions ? (
                  Object.keys(userData.divisions).map((division) => {
                    return (
                      <Tab.Pane eventKey={`#${division}`}>
                        <DivisionTab division={division} userData={userData} />
                      </Tab.Pane>
                    );
                  })
                ) : (
                  <ListGroup.Item>...</ListGroup.Item>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </StylizedBG>
  );
}
