import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
  SplitButton,
  Form,
  Button,
  Stack,
  Spinner,
} from "react-bootstrap";
import { AuthContext } from "../App";
import { useContext } from "react";

import Line from "../components/Line";
import FindUserCard from "../components/FindUserCard";
import CreateProfile from "../components/CreateProfile";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DiscordUserCard from "../components/DiscordUserCard";
import StylizedBG from "../components/StylizedBG";

export default function AddProfile() {
  const [profileType, setProfileType] = useState("name");
  const [findUser, setFindUser] = useState();
  const [denyColor, setDenyColor] = useState("white");
  const [userCards, setUserCards] = useState([]);
  const [currentMode, setCurrentMode] = useState("search");
  const [editingUserData, setEditingUserData] = useState();
  const [discordUserCards, setDiscordUserCards] = useState();
  const [userDatas, setUserDatas] = useState();
  const [
    authKey,
    setAuthKey,
    authed,
    setAuthed,
    timeCreated,
    setTimeCreated,
    modalShow,
    setModalShow,
    modalData,
    setModalData,
  ] = useContext(AuthContext);
  const style = {
    color: denyColor,
    border: "1px white",
    "--bs-dropdown-link-color": "white",
    height: "80%",
  };

  const webUrl = "https://kal-057.web.app";
  const navigate = useNavigate();

  const profileTypeChanged = (e) => {
    setProfileType(e.target.id);
    setFindUser("");
  };

  const onFindUserInput = (e) => {
    if (Number(e.target.value) && profileType === "uid") {
      setFindUser(e.target.value);
    } else if (profileType === "name") {
      setFindUser(e.target.value);
    }
  };

  const handleCreateUser = (e) => {
    setFindUser("");
    // setUserCards([])
    setCurrentMode("create");
    setEditingUserData(e);
    console.log(e);
  };

  const handleCreateDiscordUser = (e) => {
    setFindUser("");
    // setUserCards([])
    // setCurrentMode("create");
    console.log("wtf");
    setEditingUserData(e);
    console.log(e);
    const url = document.MAIN_URL + `/getRbxUserByDiscord/${e.id}`;
    console.log("????");
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/getRbxUserByDiscord/${e.id}`, // /getRbxUserByDiscord/:id
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    console.log("?????????");
    console.log(url);
    let userArr = [];
    axios(config).then((resp) => {
      console.log(resp);
      if (resp.error) {
        setModalShow(true);
        setModalData({ header: "SERVER ERROR", bodyText: resp });
        return;
      }
      const { discriminator, displayAvatarURL, username } = e;
      const compiledData = {
        discriminator,
        displayAvatarURL,
        username,
        ...resp.data,
      };
      setEditingUserData(compiledData);
      console.log(compiledData);
      setCurrentMode("create");
      const userData = {};
    });
  };

  const handleGetDiscordMembers = (e) => {
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/getDiscordMembers`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    console.log("??????");
    let userArr = [];
    axios(config).then((resp) => {
      console.log(resp);

      // resp.data.map((user, index) => {
      //   userArr[index] = (
      //     <DiscordUserCard
      //       userData={user}
      //       handleAdd={handleCreateDiscordUser}
      //       // handleAdd={handleCreateUser}
      //     />
      //   );
      // });
      setUserDatas(resp.data);
    });
  };

  const handleFindUser = (e) => {
    e.preventDefault();
    if (profileType !== "name" || findUser === "") return;
    setCurrentMode("search");
    //document.MAIN_URL
    const url = document.MAIN_URL + `/getRbxUsersByName/${findUser} `;
    fetch(url, { headers: { Authorization: localStorage.getItem("key") } })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data.message || data.errors) {
          console.log("ERR", data);
          // alert(data.message);
          setModalData({
            header: "SERVER ERROR",
            bodyText: `RBX API ERR :: ${
              data.message ? data.message : data.errors[0].message
            }`,
          });
          setModalShow(true);
          navigate("/");
          return;
        }
        let userArr = [];
        console.log(data); //   data["data"].map when using rbx api
     
        // data["data"].map((user, index) => {
        //   userArr[index] = (
        //     <FindUserCard
        //       key={index}
        //       name={user.name}
        //       id={user.id}
        //       hasVerifiedBadge={user.hasVerifiedBadge}
        //       displayName={user.displayName}
        //       previousUsernames={user.previousUsernames}
        //       handleAdd={handleCreateUser}
        //     />
        //   );
        // });
        // setUserCards(userArr);
      })
      .catch(alert);
  };

  useEffect(() => {
    handleGetDiscordMembers();
  }, []);
  const levenshteinDistance = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
      arr[i] = [i];
      for (let j = 1; j <= s.length; j++) {
        arr[i][j] =
          i === 0
            ? j
            : Math.min(
                arr[i - 1][j] + 1,
                arr[i][j - 1] + 1,
                arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
              );
      }
    }
    return arr[t.length][s.length];
  };
  useEffect(() => {
    // we will now sort userDatas by name
    if (!userDatas) return; // use username prop of userDatas
    // finduser is the search prompt
    setUserDatas((prevData) => {
      prevData.sort((a, b) => {
        // we must use finduser to sort by closest match
        // we will use the levenshtein distance algorithm
        // https://en.wikipedia.org/wiki/Levenshtein_distance
       
        const aName = a.username?.toLowerCase();
        const bName = b.username?.toLowerCase();
        const findUserLower = findUser?.toLowerCase();
        const aDist = levenshteinDistance(aName, findUserLower);
        const bDist = levenshteinDistance(bName, findUserLower);
        console.log(aDist, bDist)
        return aDist - bDist;
      });
      return prevData;
    }); // lets make this use prev state for security
  }, [findUser]);

  return (
    <>
      <br />

      <br />
      <div className="add-profile">
      <StylizedBG canvas SPEED={0.001} />
        <Row>
          <Stack direction="horizontal" gap={3}>
            <InputGroup
              isInvalid={true}
              className="mb-3"
              style={style}
              onChange={onFindUserInput}
            >
              <DropdownButton
                variant="dark"
                title="find user by"
                id="input-group-dropdown-1"
                style={style}
              >
                {/* <Dropdown.Item href="#" onClick={profileTypeChanged} id="uid">
                  User ID
                </Dropdown.Item>
                <Dropdown.Divider /> */}
                <Dropdown.Item href="#" onClick={profileTypeChanged} id="name">
                  Username
                </Dropdown.Item>
              </DropdownButton>

              <Form.Control
                aria-label="Text input with dropdown button"
                value={findUser}
              />
              <Button
                variant="outline-secondary"
                id="button-addon1"
                onClick={handleFindUser}
              >
                {`find by ${profileType}`}
              </Button>
            </InputGroup>

            {/* <label className="nav-button" id="/search" >
                search
              </label> */}
          </Stack>
        </Row>
        <Button
          variant="outline-secondary"
          id="button-addon1"
          onClick={handleGetDiscordMembers}
        >{`get discord members`}</Button>
        <br />
        <br />
        {currentMode === "search" ? (
          <Row>
            {" "}
            <div className="find-user-results">
              {userDatas ? 
                userDatas.map((user, index) => {
                  return (
                    <DiscordUserCard
                    userData={user}
                    handleAdd={handleCreateDiscordUser}
                    // handleAdd={handleCreateUser}
                  />
                  );
                })
               : (
                <Spinner color="white" animation="border" />
              )}
            </div>
          </Row>
        ) : (
          <CreateProfile
            userData={editingUserData}
            setCurrentMode={setCurrentMode}
          />
        )}
      </div>
    </>
  );
}
