import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";

export default function DeleteRoleModal({
  onClose,
  onConfirm,
  show,
  setShow,
  permissions,
  divData,
  division,
  deleteRole,
  setFallbackRole,
}) {
  const handleClose = () => {setShow(false); setSelectedRole(null);setFallbackRole(null);};
  const handleShow = () => setShow(true);
  const [selectedRole, setSelectedRole] = useState(null);

  const onRoleSelected = (e) => {
    console.log(e);
    setSelectedRole(e);
    setFallbackRole(e);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please choose a role for members of {divData.roles[deleteRole]?.name}#{deleteRole} to be assigned to.
        <Dropdown
          onSelect={(e) => {
            onRoleSelected(e);
          }}
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
               {selectedRole ? selectedRole : "Select a role"}&nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>
            {Object.keys(divData.roles).map((role, i) => {
              if (role === deleteRole) return null;
              return (
                <Dropdown.Item
                  disabled={
                    !permissions?.canRank || Number(role) >= permissions.maxRank
                  }
                  eventKey={role}
                >
                  {divData.roles[role].name + " : "}{" "}
                  <span className="find-user-property-3">{role}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>Confirm Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}
