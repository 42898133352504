import { Form } from "react-bootstrap"

export default function DivTableEntry({ div,onDivAdded }) {


    return (
        <tr id = {div} >
            <td style={{ fontVariant: "small-caps!important" }}>      
              <Form.Check
            inline
            label=""
            name="group1"
            type={"checkbox"}
            id={`inline-checkbox-2`}
            value = {div}
            onChange={onDivAdded}
          /></td>
            <td style={{ fontVariant: "small-caps!important" }}>{div.toLowerCase()}</td>
        </tr>
    )
}