import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "./Line";

export default function NewApplication({
  division,
  divData,
  forDivision,
  divPermissions,
  meta
}) {
  // we will be using AI to analyze open ended text answers
  const componentData = {
    header: {
      type: "header",
      label: "header..",
      component: "header",
    },
    text: {
      type: "text",
      defaultValue: "set default value",
      label: "set label",
      component: "form.control",
      required: false,
      idealAnswer: "set ideal answer",
    },
    color: {
      type: "color",
      label: "set label",

      defaultValue: "#000000",
      component: "form.control",
      required: false,
    },
    textarea: {
      type: "textarea",
      label: "set label",
      rows: 3,
      defaultValue: "set default value",
      component: "form.control",
      required: false,
      idealAnswer: "set ideal answer",
    },
    select: {
      maxOptions: 10,
      type: "select",
      label: "select option..",
      options: ["option 1", "option 2", "option 3"],
      correctOptions: ["option 1"],
      component: "form.select",
      required: false,
    },
    radio: {
      maxOptions: 10,
      type: "radio",
      label: "select option..",
      options: ["option 1", "option 2", "option 3"],
      correctOptions: ["option 1"],
      component: "form.check",
      required: false,
    },
  };

  const getComponent = {
    text: (componentData, onChange) => {
      return (
        <Form.Control
          type={componentData.type}
          onChange={onChange}
          label={componentData.label}
        />
      );
    },
    color: (componentData, onChange) => {
      return (
        <Form.Control
          type={componentData.type}
          onChange={onChange}
          label={componentData.label}
        />
      );
    },
    textarea: (componentData, onChange) => {
      return (
        <Form.Control
          as={componentData.type}
          onChange={onChange}
          rows={componentData.rows}
          label={componentData.label}
        />
      );
    },
    select: (componentData, onChange) => {
      return (
        <>
          <Form.Control
            as={componentData.type}
            onChange={onChange}
            label={componentData.label}
          >
            {componentData.options.map((option) => {
              return <option>{option}</option>;
            })}
          </Form.Control>
        </>
      );
    },
    radio: (componentData, onChange) => {
      return (
        <>
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Col sm={10}>
                {componentData.options.map((option) => {
                  return (
                    <>
                      <Form.Check
                        type={componentData.type}
                        label={
                          <span style={{ fontVariant: "all-small-caps" }}>
                            &nbsp;{option}
                          </span>
                        }
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </>
                  );
                })}
              </Col>
            </Form.Group>
          </fieldset>
        </>
      );
    },
    header: (componentData, onChange) => {
      return (
        <>
          <h2 style={{ color: "white" }}>{componentData.label}</h2>
        </>
      );
    },
  };
  
  const [notification, setNotification] = useState("");
  const [notifShow, setNotifShow] = useState(false);
  
  const componentElements = {};

  const componentCard = (
    <>
      <Card>data here</Card>
    </>
  );

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    console.log("?!!!!!!")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return
    }

    setValidated(true);
    const applicationCompleted = {
      applicationMetaData,
      applicationData,
    }
    console.log(applicationCompleted);
    const endpoint = !meta ? '/createApplication' : '/createMetaApplication';
    const config = {
      method: "POST",
      url: document.MAIN_URL+`${endpoint}`, 
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {application:applicationCompleted,division},
    }
    console.log(applicationCompleted)
    axios(config).then((res) => {

      if (res?.data) {
        console.log(res.data);
        setNotification(res.data.message);
        setNotifShow(true);
        if (res.data.success) {
          setApplicationData([]);
          setApplicationMetaData({
            division,
            onAccept: {
              addToDivision: false,
              setRole: false,
              role: 0,
              setRank: false,
              rank: 0,
              message: "",
            },
          });
        }
      }
    }).catch(err=>{
      console.log(err);
      setNotifShow(true);
      setNotification(err.response.data?.message);

    });
  };

  const [componentsList, setComponentsList] = useState([]);
  const [applicationComponents, setApplicationComponents] = useState([]); // list of finished application components to be rendered after prototyping
  const [applicationData, setApplicationData] = useState([]);
  const [applicationMetaData, setApplicationMetaData] = useState({
    division,
    onAccept: {
      addToDivision: false,
      setRole: false,
      role: 0,
      setRank: false,
      rank: 0,
      message: ""
    },
  }); // set the application metadata
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData'))); //
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    setApplicationData((data) => {
      const copyListItems = [...data];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      return copyListItems;
    });
  };

  useEffect(() => {
    const components = [];

    Object.keys(componentData).forEach((component, index) => {
      components.push(
        <Card>
          <Card.Header style={{ color: "white", fontVariant: "small-caps" }}>
            <Stack direction="horizontal" gap={1000}>
              {componentData[component].type}
              <div className="p-2 ms-auto">
                <Button
                  variant="outline-primary"
                  style={{ color: "white" }}
                  onClick={(e) => {
                    setApplicationData((data) => {
                      const copyData = [...data];
                      copyData.push(componentData[component]);
                      return copyData;
                    });
                  }}
                >
                  add component
                </Button>
              </div>
              <div className="p-2"></div>
            </Stack>
          </Card.Header>
          <Card.Body>
            {getComponent[componentData[component].type]
              ? getComponent[componentData[component].type](
                  componentData[component],
                  () => {}
                )
              : null}
          </Card.Body>
        </Card>
      );
    });
    console.log(applicationData);
    setComponentsList(components);
  }, [applicationData]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const sendPreviewMessage = () => {
    console.log(applicationMetaData.onAccept)
    const config = {
      method: "POST",
      url: document.MAIN_URL + "/previewMessage",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        message: applicationMetaData.onAccept.message,
      },
    };
    console.log(config)
    axios(config).then((res) => {
      console.log(res.data);
      if (res.data.message) {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    }).catch(console.log);
  }

  return (
    <>
          <ToastContainer
        className="p-3"
        position={"top-end"}
        style={{ zIndex: 9999999999999999,position:'absolute' }}
      >
        <Toast
          onClose={() => setNotifShow(false)}
          show={notifShow}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2" 
              alt=""
            />
            <strong className="me-auto">Action Notification</strong>
            <small style={{ fontVariant: "small-caps" }}>kaleida</small>
          </Toast.Header>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Container className="app-builder" fluid>
        {/*applicationMetaData form here*/}
        <Row>
          <h3 className="app-header">application metadata</h3>
          <Form as={Col} sm={3} noValidate validated={validated} >
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Enter Application Name"
                sm={1}
              >
                <Form.Control
                required
                  type="text"
                  placeholder="
                  Enter Application Name"
                  onChange={(e) => {
                    setApplicationMetaData((data) => {
                      const copyData = { ...data };
                      copyData.name = e.target.value;
                      return copyData;
                    });
                  }}
                />
                 <Form.Control.Feedback></Form.Control.Feedback>
              </FloatingLabel>
              <br />
              <FloatingLabel
                controlId="floatingInput"
                label="Enter Application Description"
              >
                <Form.Control
                required
                  type="textarea"
                  rows={3}
                  placeholder="
                  Enter Application Description"
                  onChange={(e) => {
                    setApplicationMetaData((data) => {
                      const copyData = { ...data };
                      copyData.description = e.target.value;
                      return copyData;
                    });
                  }}
                />
              </FloatingLabel>
              
            </Form.Group>
            <br />
            <Button type = "submit" variant={'outline-success'} onClick={handleSubmit}>post application</Button>
          </Form>

          <Col sm={4}>
            {" "}
            {/*onAccept form for applicationMetaData here*/}
            <Label className={"spec-disp " + (meta===false ? 'black' : 'white')} style={{ fontVariant: "small-caps" }}>
              on application accept:{" "}
            </Label>
            {forDivision ? (
              <Form >
                <Form.Group>
                  <Form.Check
                    label={
                      <span
                        className="spec-disp black"
                        style={{ fontVariant: "small-caps" }}
                      >
                        &nbsp;add to division?
                      </span>
                    }
                    type="checkbox"
                    placeholder="add to division?"
                    onChange={(e) => {
                      setApplicationMetaData((data) => {
                        const copyData = { ...data };
                        copyData.onAccept.addToDivision = e.target.checked;
                        return copyData;
                      });
                    }}
                  />

                  <Form.Check
                    label={
                      <span
                        className="spec-disp black"
                        style={{ fontVariant: "small-caps" }}
                      >
                        &nbsp;set div role?
                      </span>
                    }
                    type="checkbox"
                    placeholder="set rank?"
                    onChange={(e) => {
                      setApplicationMetaData((data) => {
                        const copyData = { ...data };
                        copyData.onAccept.setRole = e.target.checked;
                        console.log(e.target.checked);
                        return copyData;
                      });
                    }}
                  />

                  <br />
                  {applicationMetaData.onAccept.setRole ? (
                    <FloatingLabel
                      controlId="floatingInput"
                      label={
                        <span
                          className="spec-disp"
                          style={{ fontVariant: "small-caps" }}
                        >
                          &nbsp;div role:{" "}
                          {
                            divData.roles[applicationMetaData.onAccept.role]
                              ?.name
                          }
                        </span>
                      }
                      sm={1}
                    >
                      <Form.Control
                        type="number"
                        placeholder="rank"
                        value={applicationMetaData.onAccept.role}
                        onChange={(e) => {
                          setApplicationMetaData((data) => {
                            console.log(divPermissions);
                            const copyData = { ...data };
                            const num = parseInt(e.target.value);
                            const max = parseInt(divPermissions.maxRank);
                            const numSet =
                              num <= 0 ? 0 : num >= max ? max - 1 : num;
                            console.log(num <= 0 ? 0 : num >= max ? max : num);
                            if (divData.roles[numSet]) {
                              copyData.onAccept.role = numSet;
                            } else {
                              // find the next highest role 
                              let nextHighestRole = 0;
                              Object.keys(divData.roles).forEach((role) => {
                                if (
                                  parseInt(role) > nextHighestRole &&
                                  parseInt(role) < max
                                ) {
                                  nextHighestRole = parseInt(role);
                                }
                              });
                              copyData.onAccept.role = nextHighestRole;
                            }
                            return copyData;
                          });
                        }}
                      />
                    </FloatingLabel>
                  ) : (null)}
                  
                </Form.Group>
                
              </Form>
            ) : (<>
                 <Form >
                <Form.Group>
        

                  <Form.Check
                    label={
                      <span
                        className="spec-disp white"
                        style={{ fontVariant: "small-caps" }}
                      >
                        &nbsp;set global rank?
                      </span>
                    }
                    type="checkbox"
                    placeholder="set rank?"
                    onChange={(e) => {
                      setApplicationMetaData((data) => {
                        const copyData = { ...data };
                        copyData.onAccept.setRank = e.target.checked;
                        console.log(e.target.checked);
                        return copyData;
                      });
                    }}
                  />

                  <br />
                  {applicationMetaData.onAccept.setRank ? (
                    <FloatingLabel
                      controlId="floatingInput"
                      label={
                        <span
                          className="spec-disp"
                          style={{ fontVariant: "small-caps" }}
                        >
                          &nbsp;global rank:{" "}
                   
                        </span>
                      }
                      sm={1}
                    >
                      <Form.Control
                        type="number"
                        placeholder="rank"
                        value={applicationMetaData.onAccept.rank}
                        onChange={(e) => {
                          setApplicationMetaData((data) => {
            
                            const copyData = { ...data };
                            const num = parseInt(e.target.value);
                            const max = parseInt(userData.rank);
                            function clamp(number, min, max) {
                              return Math.max(min, Math.min(number, max));
                            }
                            const numSet = clamp(num,1,4)
                            console.log(num <= 0 ? 0 : num >= max ? max : num);
                              // find the next highest role 
       
                       
                              copyData.onAccept.rank = numSet;
                            
                            return copyData;
                          });
                        }}
                      />
                    </FloatingLabel>
                  ) : (null)}
                </Form.Group>

                
              </Form>
            </>)}
          </Col>
          <Col sm={4}>
            {
              // acceptance message for discord bot, with button to preview the message
            }
            <InputGroup>
          <FloatingLabel
                controlId="floatingInput"
                label="Acceptance Message"
              >
                <Form.Control
                required
                as='textarea'
                  type="textarea"
                  rows={1}
                  placeholder="
                  Enter Application Description"
                  onChange={(e) => {
                    setApplicationMetaData((data) => {
                      console.log(e.target.value);
                      const copyData = { ...data };
                      copyData.onAccept.message = e.target.value;
                      console.log(copyData)
                      return copyData;
                    });
                  }}
                />
                
              </FloatingLabel>
              <Button variant="outline-primary" onClick={sendPreviewMessage}>preview</Button>
            </InputGroup>
          </Col>
        </Row>
        <Line />
        <Row>
          <Col>
            <h3 className="app-header">components</h3>
          </Col>
          <Col>
            {" "}
            <h3 className="app-header">prototype</h3>
          </Col>
          <Col>
            {" "}
            <h3 className="app-header">preview</h3>
          </Col>
        </Row>
        <Row className="new-app">
          {" "}
          <Col className="add-components">{componentsList}</Col>
          <Col className="add-components">
            <Stack direction="vertical" gap={2}>
              {applicationData.map((component, index) => {
                if (getComponent[component.type]) {
                  return (
                    <Card key={index}>
                      <Card.Header>
                        <Stack direction="horizontal" gap={1000}>
                          <div
                            className="p-2"
                            style={{
                              fontVariant: "small-caps",
                              background: "white",
                              backgroundSize: "cover",
                              backgroundAttachment: "fixed",
                              borderRadius: "5px",
                              
                              textAlign:"center",
                              textAlignVertical:"center",
                            }}
                          >
                            {index}
                          </div>
                          <div
                            className="p-2"
                            style={{
                              color: "white",
                              fontVariant: "small-caps",
                            }}
                          >
                            {"<" + component.type + "/>"}
                          </div>
                          <div className="p-2 ms-auto">
                            <Button
                              variant="outline-primary"
                              style={{ color: "white" }}
                              onClick={(e) => {
                                setApplicationData((data) => {
                                  const copyData = [...data];
                                  copyData.splice(index, 1);
                                  return copyData;
                                });
                              }}
                            >
                              remove
                            </Button>
                          </div>
                          <div className="p-2"> </div>
                        </Stack>
                      </Card.Header>
                      {/*way to edit options */}
                      <Card.Body>
                        {/* way to specify if field is required*/}
                        <Stack direction="vertical" gap={1}>
                          {typeof component.required === 'boolean' ? (
                            <Form.Check
                              type="checkbox"
                              label={
                                <span style={{ fontVariant: "small-caps" }}>
                                  &nbsp;required field?
                                </span>
                              }
                              onChange={(e) => {
                                setApplicationData((data) => {
                                  const copyData = [...data];
                                  copyData[index].required = e.target.checked;
                                  return copyData;
                                });
                              }}
                            />
                          ):null}
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Enter Label"
                            sm={1}
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              onChange={(e) => {
                                setApplicationData((data) => {
                                  const copyData = [...data];
                                  copyData[index].label = e.target.value;
                                  return copyData;
                                });
                              }}
                            />
                          </FloatingLabel>
                          {component.idealAnswer ? (
                            <FloatingLabel
                              controlId="floatingInput"
                              label={
                                <span>
                                  Enter Ideal Answer{" "}
                                  <small
                                    className="find-user-property-3"
                                    style={{ fontVariant: "small-caps" }}
                                  >
                                    {"<"}ai analysis{">"}
                                  </small>
                                </span>
                              }
                              sm={1}
                            >
                              <Form.Control
                                type="text"
                                placeholder={
                                  <span>
                                    Enter Ideal Answer{" "}
                                    <small>ai analysis</small>
                                  </span>
                                }
                                onChange={(e) => {
                                  setApplicationData((data) => {
                                    const copyData = [...data];
                                    copyData[index].idealAnswer =
                                      e.target.value;
                                    return copyData;
                                  });
                                }}
                              />
                            </FloatingLabel>
                          ) : null}
                          {component.options ? (
                            <>
                              <Accordion className="component-accordion">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    add option
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        const added = e.target[1].value;
                                        e.target[1].value = "";
                                        // detect if we are reaching the maxOptions limit
                                        if (
                                          component.options.length >=
                                          component.maxOptions
                                        ) {
                                          return;
                                        }
                                        setApplicationData((data) => {
                                          const copyData = [...data];
                                          if (
                                            copyData[index].options.includes(
                                              added
                                            )
                                          ) {
                                            return copyData;
                                          }
                                          copyData[index].options.push(added);
                                          return copyData;
                                        });
                                      }}
                                    >
                                      <Stack direction="horizontal" gap={2}>
                                        <Button
                                          type="submit"
                                          id={"add-option-" + index}
                                        >
                                          Add
                                        </Button>

                                        <Form.Control
                                          required
                                          type="text"
                                          placeholder="add option"
                                        />
                                      </Stack>
                                    </Form>
                                      <br/>
                                    <Table
                                      variant="dark"
                                      hover
                                      className="add-option-table"
                                      borderless
                                    >
                                      <thead>
                                        <tr>
                                          <th>option</th>
                                          <th>correct</th>
                                          <th>remove</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {component.options.map((option, i) => {
                                          return (
                                            <tr>
                                              <td>{option}</td>
                                              <td>
                                                <Form.Check
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    setApplicationData(
                                                      (data) => {
                                                        const copyData = [
                                                          ...data,
                                                        ];
                                                        copyData[
                                                          index
                                                        ].correctOptions[i] =
                                                          e.target.checked;
                                                        return copyData;
                                                      }
                                                    );
                                                  }}
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  variant="outline-primary"
                                                  onClick={(e) => {
                                                    setApplicationData(
                                                      (data) => {
                                                        const copyData = [
                                                          ...data,
                                                        ];
                                                        const optionIndex =
                                                          copyData[
                                                            index
                                                          ].options.indexOf(
                                                            option
                                                          );
                                                        if (
                                                          optionIndex === -1
                                                        ) {
                                                          return copyData;
                                                        }
                                                        copyData[
                                                          index
                                                        ].options.splice(
                                                          optionIndex,
                                                          1
                                                        );

                                                        return copyData;
                                                      }
                                                    );
                                                  }}
                                                >
                                                  remove
                                                </Button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </>
                          ) : null}
                          <br />

                          {getComponent[component.type](component, () => {})}
                        </Stack>
                      </Card.Body>
                    </Card>
                  );
                }
              })}
            </Stack>
          </Col>
          <Col className="add-components">
            <div className="finished-proto">
              {applicationData.map((component, index) => {
                return (
                  <>
                    <FormGroup>
                      {component.type !== "header" ? (
                        <Label style={{ fontVariant: "all-small-caps" }}>
                          {component.label}
                        </Label>
                      ) : null}
                      {getComponent[component.type](component, () => {})}
                    </FormGroup>
                  </>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// draggable
// onDragStart={(e) => dragStart(e, index)}
// onDragEnter={(e) => dragEnter(e, index)}
// onDragEnd={drop}
