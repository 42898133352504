import StylizedBG from "./StylizedBG";

import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "../components/Line";
import Notification from "../components/Notification";
import { Link, createSearchParams, useNavigate } from "react-router-dom";

export default function Application({ questions }) {
  const [applicationData, setApplicationData] = useState(null);
  const [answerData, setAnswerData] = useState({});
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [notifShow, setNotifShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifMessage, setNotifMessage] = useState("");

  const getComponent = {
    text: (componentData, onChange) => {
      return (
        <Form.Control
          type={componentData.type}
          onChange={onChange}
          label={componentData.label}
          style={{ width: "70%" }}
          required={componentData.required}
        />
      );
    },
    color: (componentData, onChange) => {
      return (
        <Form.Control
          type={componentData.type}
          onChange={onChange}
          label={componentData.label}
        />
      );
    },
    textarea: (componentData, onChange) => {
      return (
        <Form.Control
          as={componentData.type}
          onChange={onChange}
          rows={componentData.rows}
          label={componentData.label}
          style={{ width: "70%" }}
          required={componentData.required}
        />
      );
    },
    select: (componentData, onChange) => {
      return (
        <>
          <Form.Control
            as={componentData.type}
            onChange={(e) => onChange(e, e.target.selectedIndex)}
            label={componentData.label}
            style={{ width: "30%" }}
            required={componentData.required}
          >
            {componentData.options.map((option, i) => {
              return (
                <option id={i} name={i}>
                  {option}
                </option>
              );
            })}
          </Form.Control>
        </>
      );
    },
    radio: (componentData, onChange) => {
      return (
        <>
          <fieldset>
            <Form.Group as={Row} className="mb-3">
              <Col sm={10}>
                {componentData.options.map((option, i) => {
                  return (
                    <>
                      <Form.Check
                        type={componentData.type}
                        required={componentData.required}
                        onChange={(e) => onChange(e, i)}
                        label={
                          <span style={{ fontVariant: "all-small-caps" }}>
                            &nbsp;{option}
                          </span>
                        }
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                    </>
                  );
                })}
              </Col>
            </Form.Group>
          </fieldset>
        </>
      );
    },
    header: (componentData, onChange) => {
      return (
        <>
          <h2 style={{ color: "white" }}  className="white smallcaps mono spec-disp">{componentData.label}</h2>
        </>
      );
    },
  };
  const [validated, setValidated] = useState(false);

  // lets get our application params from the url, we will use this to send a request to the backend
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const application = urlParams.get("application");
    const division = urlParams.get("division");
    const url =
      division !== "meta"
        ? `/getPrimaryApplication/${division}`
        : `/getPrimaryApplication`;
    console.log(application, division);
    const config = {
      method: "get",
      url: document.MAIN_URL + url,
      headers: {
        authorization: localStorage.getItem("key"),
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        if (!res.data) return console.log("no application data");
        if (res.data.success &&( res.data.application && !res.data.submitted)) {
          setApplicationData(res.data.application);
        } else if (res.data.success && res.data.submitted) {
          setSubmitted(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // alert('Could not get application data..')
        // navigate('/')
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitted) return;

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const division = urlParams.get("division");
      const url =
        division !== "meta"
          ? `/submitApplication/division`
          : `/submitApplication`;
      const config = {
        method: "post",
        url: document.MAIN_URL + url,
        headers: {
          authorization: localStorage.getItem("key"),
        },
        data: {
          application: applicationData.applicationMetaData.name,
          applicationData: applicationData.applicationMetaData,
          answerData: answerData,
          division: applicationData.applicationMetaData.division,
        },
      };
      setSubmitted(true);
      axios(config)
        .then((res) => {
          if (!res.data.success) {
            setNotifMessage("Error submitting application.!");
            setNotifShow(true);
            setSubmitted(false);
            return console.log("no application data");
          }
          setNotifMessage("Application submitted!");
          setNotifShow(true);
        })
        .catch((err) => {
          console.log(err);
          // alert("Server error..");
          // navigate("/");
        });
    }
    setValidated(true);
  };

  const onChange = (e, i, optIndex) => {
    const answer = optIndex === undefined ? e.target.value : optIndex;
    const question = applicationData.applicationData[i].label;
    setAnswerData((prevData) => {
      prevData[Number(i)] = answer;
      return prevData;
    });
  };

  return (
    <>
      <StylizedBG canvas SPEED={0.001} />
      <Notification
        show={notifShow}
        setShow={setNotifShow}
        notification={notifMessage}
      />
      {!submitted ? (
        <Container>
          <div className="backblur-1 application">
            {applicationData ? (
              <>
                <Stack direction="vertical" gap={3}>
                  <Stack direction="horizontal" gap={3}>
                    <span className="white smallcaps mono">

                      <span className="white smallcaps mono spec-disp" style ={{fontSize:"200%"}}>
                        {
                         applicationData.applicationMetaData.name
                        }
                      </span>
                    </span>
                  </Stack>
                  <span className="white smallcaps mono">description</span>
                  <span className="white mono small">
                    {
                    applicationData.applicationMetaData.description
                    }
                  </span>
                </Stack>
              </>
            ) : null}
            <br />
            <Form
              className=""
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              validated={validated}
            >
              {applicationData
                ? applicationData.applicationData.map((component, index) => {
                    return (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId={`validationCustom${index}`}
                          required={component.required}
                        >
                          {component.type !== "header" ? (
                            <>
                              <Label style={{ fontVariant: "all-small-caps" }}  className="white smallcaps mono spec-disp">
                                {component.label}
                              </Label>
                            </>
                          ) : null}
                          {getComponent[component.type](
                            component,
                            (e, optionIndex) => {
                              onChange(e, index, optionIndex);
                            }
                          )}
                        </Form.Group>
                        <br />
                      </>
                    );
                  })
                : null}
              <Button
                variant="outline-success"
                className="mbm-dif"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
        </Container>
      ) : !loading ? (
        <Container>
          <div className="backblur-1 application">
            <br />
            <h1 className="mbm-dif">Application submitted!</h1>
            <br />
            <Link to="/">
              <Button className="mbm-dif backblur-1">go back</Button>
            </Link>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="backblur-1 application">
            <br />
            <h1 className="mbm-dif">Submitting application...</h1>
            <br />
            <Spinner animation="border" role={"application"} size="lg">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </Container>
      )}
    </>
  );
}
