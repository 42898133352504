import { color, R, G, B } from "../functions";
import { useRef, useEffect } from "react";

export default function StylizedBG({ children, canvas, SPEED ,style}) {
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    SPEED = SPEED || 0.1;
    if (canvas) {
      const ctx = canvas.getContext("2d");

      let time = 0;

      const loop = function () {
        for (let x = 0; x <= 32; x++) {
          for (let y = 0; y <= 32; y++) {
            color(ctx, {
              x,
              y,
              r: R(x, y, time),
              g: G(x, y, time),
              b: B(x, y, time),
            });
          }
        }

        time = time + SPEED;

        window.requestAnimationFrame(loop);
      };

      loop();
    }
  }, []);

  return (
    <>
      {canvas ? (
        <div className="flowing-gradient">
        <canvas
          className="canvas-grad"
          ref={canvasRef}
          width="20vw"
          height="20vw"
          style={style ? style : {}}
        > </canvas>
          {children}
       
        </div>
      ) : (
        <div className="white-bg">
          {" "}
          <div className="back-blur">{children}</div>{" "}
        </div>
      )}
    </>
  );
}
