export default function Line ({color,backgroundColor,height,width,position,opacity}) {
    height = !height ? 5 : height

    return (

            <hr
                style={{
                    color: color,
                    backgroundColor: backgroundColor,
                    height: height,
                    width: !width ? "100%" : width,
                    position: (!position ? "" : position)+"!important",
                    opacity: !opacity ? 1 : opacity
                }}
            />

    )
}