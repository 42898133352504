import { IconSize } from "@blueprintjs/core";
import {
  Card,
  Col,
  ListGroup,
  Row,
  Table,
  Stack,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import { ChevronLeft } from "react-bootstrap-icons";
import DivTableEntry from "./DivTableEntry";
import { useState,useEffect } from "react";
import Line from "./Line";
import WarningModal from "./WarningModal";
import { AuthContext } from "../App";
import { useContext } from "react";
import axios from "axios";

export default function CreateProfile({ userData, setCurrentMode }) {
  const {
    id,
    name,
    displayName,
    hasVerifiedBadge,
    previousUsernames,
    handleAdd,
    discriminator,
    displayAvatarURL,
    discordId,
    description,
    username,
  } = userData;

  const [rank, setRank] = useState(0);
  const [divisionsAdded, setDivisionsAdded] = useState([]);
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    const config = {
      url: document.MAIN_URL+"/divisions", 
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    console.log(config);
    axios(config)
      .then((successResult) => {
        console.log(successResult);
        const divisionsString = successResult.data.map((div) => {
          console.log(div);
          return div.divisionName;
          });
        setDivisions(divisionsString);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const opRank = 11;
  let rankOptions = [];
  const authKey = localStorage.getItem("authKey");
  const [
    _,
    setAuthKey,
    authed,
    setAuthed,
    timeCreated,
    setTimeCreated,
    modalShow,
    setModalShow,
    modalData,
    setModalData,
  ] = useContext(AuthContext);
  const onRankSet = (e) => {
    console.log(e.target.value);
    setRank(e.target.value);
  };

  const onDivAdded = (e) => {
    console.log(divisionsAdded);
    const div = e.target.value;
    const divsAdded = [...divisionsAdded];
    for (let i = 0; i < divisionsAdded.length; i++) {
      if (divisionsAdded[i] === div) {
        divsAdded.splice(i, 1);
        setDivisionsAdded(divsAdded);
        return;
      }
    }
    divsAdded.push(div);
    setDivisionsAdded(divsAdded);
    console.log(divisionsAdded);
  };

  const onCreateProfile = async (e) => {
    if (Number(rank) <= 0) {
      setModalData({
        header: "Error",
        bodyText:
          "Cannot create profile and add user to database with rank zero.",
      });
      setModalShow(true);
      return;
    }
    // const profileData = {
    //   communityData: {
    //     rank: Number(rank),
    //     divisions: divsFormatted,
    //   },
    // };
    // console.log(profileData);
    const data = {
      divisionsAdded,
      rank,
      userData,
    };
    //https://kal-057.web.app/adddbprofile/
    const config = {
      url: document.MAIN_URL+"/adddbprofile",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data,
    };
    console.log(config);
    await axios(config)
      .then((successResult) => {
        if (successResult.status === 200) {
          console.log(successResult);
          setModalData({
            header: "Success",
            bodyText: "Profile created, returning to new profile dashboard.",
          });
          setModalShow(true);
          setTimeout(() => {
            setCurrentMode("search");
          }, 2200);
        } else {
          console.log(successResult);
          setModalData({
            header: "Warning",
            bodyText:
              "User already in database, returning to new profile dashboard.",
          });
          setModalShow(true);

          setTimeout(() => {
            setCurrentMode("search");
          }, 2200);
        }
      })
      .catch((err) => {
        console.log(err);
        setModalData({ header: "Error", bodyText: err?.message });
        setModalShow(true);
      });
  };

  for (let i = 0; i < opRank; i++) {
    rankOptions[i] = <option value={i}>{i}</option>;
  }

  return (
    <>
      <button className="button" onClick={(e) => setCurrentMode("search")}>
        return
      </button>
      <br />

      <Line
        backgroundColor={"rgba(255, 255, 255,1)"}
        height={".1px"}
        width={"300%"}
        color={"white"}
        position={"absolute"}
      />
      <Stack direction="horizontal" gap={3}>
        <div className="create-profile">
          <Card style={{ width: "18rem" }} className="create-profile-data">
            <Card.Body>
              <Card.Title>{name}</Card.Title>
              {displayAvatarURL ? (
                <>
                  {" "}
                  <Card.Title>
                    <span className="find-user-property">handle</span>{" "}
                    <span className="find-user-divider"> : : </span>
                    <span className="find-user-property-1">{username}</span>
                    <span className="find-user-property-2">#</span>
                    <span className="find-user-property-3">
                      {discriminator}
                    </span>
                  </Card.Title>
                  <Image
                    src={displayAvatarURL}
                    responsive={true}
                    rounded
                    style={{ height: "125px" }}
                    fluid
                  />
                </>
              ) : null}
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <span className="find-user-property">display name</span>{" "}
                <span className="find-user-divider"> : :</span> {displayName}
              </ListGroup.Item>
              <ListGroup.Item>
                <span className="find-user-property-1">verified</span>{" "}
                <span className="find-user-divider"> : :</span>{" "}
                {hasVerifiedBadge ? "true" : "false"}
              </ListGroup.Item>
              <ListGroup.Item>
                <span className="find-user-property-2">uid</span>{" "}
                <span className="find-user-divider"> : :</span> {id}
              </ListGroup.Item>

              {previousUsernames ? (
                <>
                  <ListGroup.Item>
                    <span className="find-user-property-3">
                      previous usernames
                    </span>
                    <span className="find-user-divider"> : : </span>
                    {previousUsernames.map((userName) => (
                      <>
                        {userName}
                        <span className="find-user-divider"> | </span>
                      </>
                    ))}
                  </ListGroup.Item>
                </>
              ) : null}
              {description ? (
                <>
                  <ListGroup.Item>
                    <span className="find-user-property-2">description</span>
                    <span className="find-user-divider"> : : </span>
                    <span className="find-user-property-4">{description}</span>
                  </ListGroup.Item>
                </>
              ) : null}
            </ListGroup>
          </Card>
          <ChevronRight
            className="create-profile-arrow"
            size={"100px"}
            color={"white"}
          />

          <Card style={{ width: "18rem" }} className="profile-creator">
            <Card.Body>
              <Card.Title style={{ fontVariant: "small-caps" }}>
                new profile
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <ListGroup.Item>
                <span className="find-user-property">rank</span>{" "}
                <span className="find-user-divider"> : :</span>
                <Form.Select
                  aria-label="set rank"
                  className="rank-selector"
                  onChange={onRankSet}
                >
                  {rankOptions}
                </Form.Select>
              </ListGroup.Item>
              <ListGroup.Item>
                <span className="find-user-property-1">divisions</span>{" "}
                <span className="find-user-divider"> : :</span>
                {divisionsAdded.map((div) => (
                  <span className="find-user-property-3">
                    {" "}
                    {div.toLowerCase()}
                    <span className="find-user-divider">;</span>{" "}
                  </span>
                ))}
              </ListGroup.Item>
              <ListGroup.Item>
                {" "}
                <Stack direction="vertical">
                  <button
                    className="button submit-button"
                    onClick={onCreateProfile}
                  >
                    create profile
                  </button>
                </Stack>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <ChevronLeft
            className="add-div-arrow"
            size={"100px"}
            color={"white"}
          />
          <div>
            <Card
              style={{ width: "120%", height: "12vw" }}
              className="add-divisions"
            >
              <Card.Body>
                <Card.Title style={{ fontVariant: "small-caps" }}>
                  set divisions
                </Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <Table striped bordered hover size="sm" className="scroll">
                  <thead className="div-list">
                    <tr>
                      <th>set</th>
                      <th>division name</th>
                    </tr>

                    {divisions.map((div) => (
                      <DivTableEntry div={div} onDivAdded={onDivAdded} />
                    ))}
                  </thead>
                  <tbody></tbody>
                </Table>
              </ListGroup>
            </Card>
          </div>
        </div>
      </Stack>

      <Line
        backgroundColor={"rgba(255, 255, 255,1)"}
        height={".1px"}
        width={"300%"}
        color={"white"}
        position={"absolute"}
      />
    </>
  );
}
