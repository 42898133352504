import AuthWithRbx from "./AuthWithRbx";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "../components/Line";

import ReactAnime from "react-animejs";
import StylizedBG from "../components/StylizedBG";
import Notification from "../components/Notification";
import { createSearchParams, useNavigate } from "react-router-dom";

export default function DashboardNU() {

  useEffect(() => {
    // refresh the page on load
    // window.location.reload();
  }, []);
  return (
    <>
      <StylizedBG
        canvas
        SPEED={0.009}
        style={{ filter: "hue-rotate(45deg) saturate(1000%) brightness(50%) sepia()" }}
      />
      <Container>
        <br />
        <Row></Row>
        <Row sm={10}>
          <Col sm = {5}>
            <h1 className="hero-header mono">kaleida</h1>
          </Col>
          <Col></Col>
        </Row>
        <Row><div><AuthWithRbx/></div></Row>
      </Container>
    </>
  );
}
