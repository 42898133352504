
import {
    Row,
    Col,
    Container,
    Navbar,
    NavbarBrand,
    Nav,
    Form,
    NavDropdown,
    Button,
    DropdownButton,
    Dropdown,
    ListGroup,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
    Image,
    Modal,
    Tabs,
    Tab,
    Table,
    Toast,
    ToastContainer,
    Stack,
    Figure,
  } from "react-bootstrap";
  import { AuthContext } from "../App";
  import React, { useContext, useEffect, useState } from "react";
  import axios from "axios";
import { Link } from "react-router-dom";

export default function  AuthWithRbx() {
    const [authURL, setAuthURL] = useState(null)
    useEffect(() => {
        const config = {
            headers: {
            },
            url: document.MAIN_URL + "/getRBXAuthURL"

        }
        console.log('...')
        axios(config).then((res) => {
            console.log(res.data)
            if (!res.data.URL) return console.log("no auth url")
            setAuthURL(res.data.URL)
            console.log("set auth url", res.data.URL)
        }).catch((err) => {
            console.log(err)
            alert('Could not authenticate with roblox..')
        }
        )
    }, []) 
 

    return(
        <> 
      <a  href={authURL} disabled>  <Button disabled = {!authURL? true : false} className="backblur auth-rbx-btn" > {!authURL? ".." : "authenticate with roblox"} </Button></a>
        </>
    )
}