import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "./Line";
import ReviewAppResponses from "./ReviewAppResponses";
import ViewEditApp from "./ViewEditApp";

export default function ManageApplications({
  division,
  divData,
  forDivision,
  divPermissions,
  getData,
}) {
  useEffect(() => {
    console.log("divData", divData);
    getData(); // lets create a warning modal for application deletion. first usestates:
  }, []);

  const [applicationDeleting, setApplicationDeleting] = useState();
  const [show, setShow] = useState(false);
  const [modalBody, setModalBody] = useState("");
  const [notification, setNotification] = useState("");
  const [notifShow, setNotifShow] = useState(false);
  const [reviewApp, setReviewApp] = useState();
  const [viewApp, setViewApp] = useState();
  const [applicationId, setApplicationId] = useState();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const onDelete = () => {
    const name = applicationDeleting;
    console.log(name);
    const config = {
      method: "POST",
      url: document.MAIN_URL + `/deleteApplication`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
        application: name,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        setApplicationDeleting(null);
        if (res.data.message) {
          setNotification(res.data.message);
          setNotifShow(true);
        }
        if (res.data.success) {
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
        setApplicationDeleting(null);
        setNotification("Something went wrong while deleting the application");
        setNotifShow(true);
      });
  };

  const onSetPrimary = (app) => {
    const config = {
      method: "POST",
      url: document.MAIN_URL + `/setDivPrimaryApplication`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
        application: app,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        if (res.data.message) {
          setNotification(res.data.message);
          setNotifShow(true);
        }
        if (res.data.success) {
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
        setNotification(
          "Something went wrong while setting the primary application"
        );
        setNotifShow(true);
      });
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            cancel
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              setShow(false);
              onDelete();
            }}
          >
            confirm
          </Button>
        </Modal.Footer>
      </Modal>{" "}
      <ToastContainer
        className="p-3"
        position={"top-end"}
        style={{ zIndex: 9999999999999999 }}
      >
        <Toast
          onClose={() => setNotifShow(false)}
          show={notifShow}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Action Notification</strong>
            <small style={{ fontVariant: "small-caps" }}>kaleida</small>
          </Toast.Header>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <ReviewAppResponses
        directory="divisions"
        getData={getData}
        orgData={divData}
        setShowToast={setNotifShow}
        setToastBody={setNotification}
        reviewApp={reviewApp}
        setReviewApp={setReviewApp}
        division={division}
      />
      <ViewEditApp
        setViewApp={setViewApp}
        viewApp={viewApp}
        directory="divisions"
        division={division}
        getData={getData}
        orgData={divData}
        setShowToast={setNotifShow}
        setToastBody={setNotification}
        appId={applicationId}
      />
      <Container className="app-builder">
        <Row>
          <Col>
            <h3>Manage Applications</h3>
            <Table className="table-variant-1 backblur" striped bordered hover>
              <thead>
                <tr>
                  <th>Application ID</th>
                  <th>Applicants</th>
                  <th>Primary</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {divData.applications
                  ? Object.keys(divData.applications).map((app) => {
                      return (
                        <tr>
                          <td className="log-data">{app}</td>
                          <td className="log-data">
                            {divData.applications[app].responses
                              ? divData.applications[app].responses.length
                              : 0}
                          </td>
                          <td className="log-data">
                            {divData.primaryApplication
                              ? divData.primaryApplication === app
                                ? "true"
                                : "false"
                              : "false"}
                          </td>
                          <td className="log-data">
                            <Stack direction="horizontal" gap={1}>
                              <Button variant="outline-success" onClick = {()=>{
                                setApplicationId(app)
                                setViewApp(divData.applications[app])
                              }}>view | edit</Button>
                              <Button
                                variant="outline-danger"
                                name={app}
                                onClick={() => {
                                  setModalBody(
                                    `Are you sure you want to delete ${app}?`
                                  );
                                  setShow(true);
                                  setApplicationDeleting(app);
                                }}
                              >
                                delete
                              </Button>
                              <Button
                                variant="outline-primary"
                                onClick={(e) => onSetPrimary(app)}
                              >
                                set as primary
                              </Button>

                              <Button
                                variant="outline-primary"
                                onClick={(e) =>
                                  setReviewApp(divData.applications[app])
                                }
                              >
                                review
                              </Button>
                            </Stack>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
