import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
  Offcanvas,
  Badge,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Clipboard2Check,
  Clipboard2X,
  Discord,
  Eye,
  FiletypeAi,
  Percent,
} from "react-bootstrap-icons";
import Line from "./Line";

export default function ViewEditApp({
  application,
  setViewApp,
  viewApp,
  directory,
  getData,
  orgData,
  setShowToast,
  setToastBody,
  appId,
  division
}) {
  {
    // can we detect if the application has been changed?
    // if so, we can show a toast that says "application has been changed, please save changes", and also disable the save button
    // showtoast = setShowToast
    // toastbody = setToastBody
    // the problem is viewApp is changed when the application is changed, so we can't detect if the application has been changed
    // we need a reference to the original application
    // we can do this by using a state variable called originalApplication

  }



  // let's detect if the application has been changed
  // we can do this by comparing the application to the viewApp
const [applicationChanged,setApplicationChanged] = useState(!false);
const [editedApplication,setEditedApplication] = useState();
  useEffect(() => {
    if (!viewApp) return;
    setEditedApplication(viewApp);
  }, [viewApp]);
  const spriteSize = "20px"; 
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setViewApp();
    setShowSidebar(false);
  };
  const handleShow = () => setShow(true);

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (!viewApp) return ;
    console.log("view app", viewApp);
    setShow(true);
  }, [viewApp]);

  useEffect(() => {
    if (!editedApplication) return;
    // compare the two
    console.log("edited application", editedApplication);
    console.log("view app", viewApp);
    if (JSON.stringify(editedApplication) !== JSON.stringify(viewApp)) {
      setApplicationChanged(!true);
    } else {
      setApplicationChanged(!false);
    }
  }, [editedApplication]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.body}
    </Tooltip>
  );

  useEffect(() => {
    console.log("RE-RENDERED!!!!");
    if (!orgData) return;
    // set reviewapp
    if (!viewApp) return;
    setViewApp(orgData.applications[viewApp.applicationMetaData.name]);
  }, [orgData]);


  const sendPreviewMessage = () => {
    console.log(viewApp.applicationMetaData.onAccept)
    const config = {
      method: "POST",
      url: document.MAIN_URL + "/previewMessage",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        message: editedApplication.applicationMetaData.onAccept.message,
      },
    };
    console.log(config)
    axios(config).then((res) => {
      console.log(res.data);
      if (res.data.message) {
        setToastBody(res.data.message);
        setShowToast(true);
      }
    }).catch(console.log);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered reviewApp>
        <Modal.Header closeButton>
          <Modal.Title>Application Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="square">
          {editedApplication && (<>
          {
            // render the modal with a form, filled out with the applicationMetaData
          }
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Application Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Application Name"
                value={editedApplication.applicationMetaData.name}
                onChange={(e) => {
                  // use oldData from setState
                  
                  setEditedApplication(oldData=>{return {...oldData,
                    applicationMetaData: {
                      ...oldData.applicationMetaData,
                      name: e.target.value,
                    },
                  }})

                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Application Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Application Description"
                value={editedApplication.applicationMetaData.description}
                onChange={(e) => {
                  // use oldData from setState
                  setEditedApplication(oldData=>{return {...oldData,
                    applicationMetaData: {
                      ...oldData.applicationMetaData,
                      description: e.target.value,
                    },
                  }})

                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {
                // application onAccept form containing onAccept.message, onAccept.rank, onAccept.setRank
              }

              <InputGroup>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Acceptance Message"
                >
                  <Form.Control
                    required
                    as="textarea"
                    type="textarea"
                    rows={1}
                    placeholder="
                  Enter Application Description"
                    onChange={(e) => {

                      // use oldData from setState
                      setEditedApplication(oldData=>{
                        return {...oldData,
                          applicationMetaData: {
                            ...oldData.applicationMetaData,
                            onAccept: {
                              ...oldData.applicationMetaData.onAccept,
                              message: e.target.value,
                            },
                          },
                        }
                      })
                     }}
                  />
                </FloatingLabel>
                <Button variant="outline-primary" onClick={sendPreviewMessage}>
                  preview
                </Button>
              </InputGroup>
            </Form.Group>
          </Form></>)
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {
            // save button
          }
          <Button
            variant="primary"
            disabled = {applicationChanged}
            onClick={() => {
              
              const config = {
                method: "POST",
                url: document.MAIN_URL + "/saveapplication",
                headers: {
                  "Content-Type": "application/json",
                  authorization: localStorage.getItem("key"),
                },
                data: {
                  directory,
                  applicationId: appId,
                  application: editedApplication.applicationMetaData,
                  division
                },
              };
              console.log(config)

              axios(config)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.success) {
                    setToastBody(res.data.message); 
                    setShowToast(true);
                    // setOrgData(res.data);
                    console.log("set org data", res.data);
                    getData();
                    setApplicationChanged(!false)
                  } else if (res.data.message) {
                    setToastBody(res.data.message);
                    setShowToast(true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>

      </Modal>

    </>
  );
}
