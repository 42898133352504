import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
} from "react-bootstrap";

import React, { useContext, useEffect, useState } from "react";

export default function SortBar({ divData,filter, setFilter, setSort ,division}) {
  const [filterTypes, _] = useState(["role", "name", "clearance"]);
  const [filterType, setFilterType] = useState("none");
  const [filterValue, setFilterValue] = useState("");
  const [sortType, setSortType] = useState("role");
  const [sortOrder, setSortOrder] = useState("ascending");
  const [filterValueSelector, setFilterValueSelector] = useState();
  const [testFunction, setTestFunction] = useState({
    func: (a, b) => {
      return a + b;
    },
  });

  const object = {};

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  object.roleFilterType = (
    <Dropdown
      onSelect={(e) => {
        setFilterValue(e);
        console.log(e);
      }}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Button variant="danger-outline" className="Button noround ">
          {filterValue ? divData.roles[filterValue]?.name : "select.."}
        </Button>
        &nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {Object.keys(divData.roles).map((role, i) => {
          return ( 
            <Dropdown.Item key={i} eventKey={role}>
              {divData.roles[role]?.name + " : "}{" "}
              <span className="find-user-property-3">{role}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );

  object.nameFilterType = (
    <Form.Control
      className="p-0 filter-search"
      placeholder="&nbsp;enter name..."
      onChange={(e) => {
        setFilterValue(e.target.value);
      }}
    />
  );

  object.clearanceFilterType = (
    <Dropdown
      onSelect={(e) => {
        setFilterValue(e);
        console.log(e);
      }}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Button variant="danger-outline" className="Button noround ">
          {filterValue ? filterValue : "select.."}
        </Button>
        &nbsp;
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {[...Array(11)].map((_, i) => {
          return (
            <Dropdown.Item key={i} eventKey={i} name={i}>
              {i !== 0 ? i : "0 : unranked"}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );


  useEffect(() => {
    console.log(filterType);
    setFilterValue("");

  }, [filterType]);

    useEffect(() => {
        console.log(filterValue);
        const filters = {
            role: (user) => {
                console.log("stuff: ",user,division,filterValue,user.divisions[division],filterValue,filterValue === false);
                return user.divisions[division] == filterValue ? user : false;
                },
            name: (user) => {
                return user.userData.name.toLowerCase().includes(filterValue.toLowerCase()) ? user : false;
                },
            clearance: (user) => {
                console.log("!!!!");
                
                return user.rank == filterValue ? user : false;
                },
            none: (user) => {
                console.log("?!!!!")
                return user;
                }
        };
        if (!filters[filterType]) return setFilter({process:(user)=>{return true}});
        setFilter({process:filters[filterType]});
    }, [filterValue])

    useEffect(() => {
        console.log(sortType);
        setSort("")
        const sortingFunctions = {
            role: (a,b) => {
                return  sortOrder === 'descending' ? b.divisions[division] - a.divisions[division] : a.divisions[division] - b.divisions[division];
                },
            name: (user) => {
               return 0
                },
            clearance: (a,b) => {
                return sortOrder === 'descending' ? b.rank - a.rank : a.rank - b.rank;
                }
        };
        setSort({process:sortingFunctions[sortType]});
    }, [sortType,sortOrder]);


  return (
    <>
      <Stack direction="horizontal" gap={0.01} className="sortbar">
        {" "}
        <sub className="nav-data noround spec-disp">filter</sub>
        <Dropdown
          onSelect={(e) => {
            setFilterType(e);
          }}
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Button variant="danger-outline" className="Button noround ">
              {filterType ? filterType : "role"}
            </Button>
            &nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>
            {filterTypes.map((role, i) => {
              return (
                <Dropdown.Item key={i} eventKey={role}>
                  <span className="find-user-property-3">{role}</span>
                </Dropdown.Item>
              );
            })}
             <Dropdown.Item key={11} eventKey={'none'}>
                  <span className="find-user-property-3">none</span>
                </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <sub className="nav-data noround spec-disp">=</sub>
        {object[filterType + "FilterType"]}
      </Stack>

      <Stack direction="horizontal" gap={0.01} className="sortbar">
      <sub className="nav-data noround spec-disp">sort</sub>
      <Dropdown
          onSelect={(e) => {
            setSortType(e);
          }}
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Button variant="danger-outline" className="Button noround ">
              {sortType ? sortType : "role"}
            </Button>
            &nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>
            {filterTypes.map((role, i) => {
              return (
                <Dropdown.Item key={i} eventKey={role}>
                  <span className="find-user-property-3">{role}</span>
                </Dropdown.Item>
              );
            })}

          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          onSelect={(e) => {
            setSortOrder(e);
          }}
        >
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Button variant="danger-outline" className="Button noround ">
              {sortOrder ? sortOrder : "Descending"}
            </Button>
            &nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>
        
                <Dropdown.Item key={'descending'} eventKey={'descending'}>
                  <span className="find-user-property-3">descending</span>
                </Dropdown.Item>
    
                <Dropdown.Item key={'ascending'} eventKey={'ascending'}>
                  <span className="find-user-property-3">ascending</span>
                </Dropdown.Item>
    
          </Dropdown.Menu>
        </Dropdown>
    </Stack>
    </>
  );
}

// {Object.keys(divData.roles).map((role, i) => {
//     return (
//       <Dropdown.Item

//           key={i}
//         eventKey={role}
//       >
//         {divData.roles[role].name + " : "}{" "}
//         <span className="find-user-property-3">{role}</span>
//       </Dropdown.Item>
//     );
//   })}
