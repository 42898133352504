import AuthWithRbx from "./AuthWithRbx";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "../components/Line";
import { Link } from "react-router-dom";
import StylizedBG from "../components/StylizedBG";


export default function TermsOfService() {
  return (
    <>
      <StylizedBG
        canvas
        SPEED={0.009}
      />
      <Container>
        <Row>
            <Col>
            <p className="mbm-dif mono spec-disp">
            <Stack direction = "vertical" gap = {3}>


            <h3><b className="ai"><i>Terms of Service (TOS) for Kaleida.net</i></b> </h3>

            <i className="review">Last updated: July 25, 2023</i>

<h4>1. Acceptance of Terms</h4>

By accessing and using Kaleida.net ("the App"), you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you must not use the App.

<h4>2. Eligibility</h4>

You must be at least 13 years old to use the App. By using the App, you represent that you are 13 years old or older.

<h4>3. Account Creation</h4>

Users can sign in to Kaleida.net using their pre-existing Roblox account. The information collected includes the user's Roblox username and Roblox user ID, along with their IP address (hashed using SHA256) to prevent abuse and multiple account creation.

<h4>4. Use of the App</h4>

<i>(a) Users must follow the Roblox Terms of Service and the Roblox Community Guidelines while using the App. We reserve the right to terminate user membership at any time, for any reason, without notice.</i>

<i>(b) Users can submit text and users with special permissions may submit images.</i>

<h4>5. Data Collection and Privacy</h4>

<i>(a) We collect and store user data, including Roblox username, Roblox user ID, and IP address (hashed using SHA256), solely for identification purposes and to prevent abuse of the App.</i>

<i>(b) The user data is stored securely in a database and is accessible only to the App and its developers.</i>

<h4>6. Tracking Technologies and Cookies</h4>

<i>The App does not use any tracking technologies or cookies.</i>

<h4>7. Third-Party Integration</h4>

<i>Kaleida.net is only integrated with Roblox for user sign-in.</i>

<h4>8. Handling User Complaints and Inappropriate Content</h4>

<i>Users can report inappropriate content, which will be reviewed by the App team, and appropriate actions will be taken.</i>

<h4>9. Account Termination and Deletion</h4>

<i>Users can delete their accounts by signing into their dashboard and clicking the "Delete Account" button, permanently removing all associated data.</i>

<h4>10. Use of App's Content and Intellectual Property</h4>

<i>Users may not use the App's content for commercial purposes or any other purpose without the App's permission.</i>

<h4>11. Disclaimer and Limitation of Liability</h4>

<i>We are not responsible for any damages caused by the App or its content.</i>

<h4>12. Privacy Policy</h4>

<Link to="/privacy-policy">Our privacy policy can be found at https://kaleida.net/privacy-policy.</Link>

<h4>13. Governing Law</h4>

<i>The laws of the United States of America govern these Terms of Service and the use of the App.</i>

<h4>14. Changes to the Terms of Service</h4>

<i>We will notify users of any changes to the Terms of Service through Discord and a visible notification on the App at the time of user log-in.</i>

<h4>15. Contact Us</h4>

<i>For questions, concerns, or support related to the App, you can contact us at <span className="accept">support@kaleida.net</span>.</i>

</Stack>
            </p>
            </Col>
        </Row>
      </Container>
    </>
  );
}
