import { BrowserRouter, Routes ,Route, Link} from 'react-router-dom';

import Header from './scenes/Header';
import AddProfile from './scenes/AddProfile';
import Dashboard from './scenes/Dashboard';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createContext, useEffect, useState } from 'react';
import WarningModal from './components/WarningModal';
import ManageMembersDash from './scenes/ManageMembersDash';
import ManageDivisionsDash from './scenes/ManageDivisionsDash';
import Logs from './scenes/Logs';
import Redirect from './scenes/Redirect';
import Application from './components/Application';
import axios from 'axios';
import ManageMeta from './scenes/ManageMeta';
import PrivacyPolicy from './scenes/PrivacyPolicy';
import TermsOfService from './scenes/TermsOfService';
document.MAIN_URL = `https://us-central1-kal-057.cloudfunctions.net/api` // `https://us-central1-kal-057.cloudfunctions.net/api` `http://localhost:3000`
document.HOST_URL = ``

export const AuthContext = createContext()



function App() {
  
  const [authKey,setAuthKey] = useState(localStorage.getItem("key"))
  const [authed,setAuthed] = useState(localStorage.getItem("authenticated"))
  const [timeCreated,setTimeCreated] = useState(new Date(Date.parse(localStorage.getItem("authTime"))))
  const [modalShow, setModalShow] = useState(false);
  const [modalData,setModalData] = useState({header:"Error",bodyText:'Cannot create profile and add user to database with rank zero.'})
  const [metaApp,setMetaApp] = useState();
  const [userData, setUserData] = useState(null);
  const [divisions, setDivisions] = useState();
  const [applications, setApplications] = useState();
  const getData = () => {
    const config = { 
      method: "GET",
      url: document.MAIN_URL + `/getSelf`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    const divConfig = {
      url: document.MAIN_URL + "/divisions",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    const metaConfig = {
      url: document.MAIN_URL + "/getPrimaryApplication",
      method: "GET", 
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };

    axios
      .all([axios(config), axios(divConfig), axios(metaConfig)])
      .then(
        axios.spread((...responses) => {
          const userData = responses[0].data;
          const divisions = responses[1].data;
          const metaApp = responses[2].data;
          const applicationsObj = {};
          const divisionsObj = {};
          divisions.map((division) => {
            divisionsObj[division.divisionName] = division;
            applicationsObj[division.divisionName] = division?.applications;
          });
          console.log(divisionsObj);
          console.log(applicationsObj);
          // if (!userData || !divisions || !applications) return
          metaApp.application.submitted = responses[2].data.submitted;
          setApplications(applicationsObj);
          setMetaApp([metaApp.application]);
          setDivisions(divisionsObj); 

          setUserData(userData);

          const dataJSON = JSON.stringify(userData);	
          localStorage.setItem("userData", dataJSON);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const key = localStorage.getItem("key");
    getData();
  }, []);

  console.log(timeCreated)
  return (
    <>

    <AuthContext.Provider value = {[authKey,setAuthKey,authed,setAuthed,timeCreated,setTimeCreated,modalShow,setModalShow,modalData,setModalData]} >
    <WarningModal
        heading={modalData.header}
        bodyText={modalData.bodyText}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    <BrowserRouter>

      <Header  userData/>
     
      <Routes>

      <Route path = "/"  element = {<Dashboard _apps={applications} _userdata={userData} _divisions={divisions} _metaapp={metaApp} />}  />
      <Route path = "/addprofile"  element={<AddProfile/>} />
      <Route path = "/managemembers"  element={<ManageMembersDash/>} />
      <Route path = "/managedivisions"  element={<ManageDivisionsDash/>} />
      <Route path = "/logs"  element={<Logs/>} />
      <Route path = '/redirect' element = {<Redirect/>} />
      <Route path = '/apply' element = {<Application/>} />
      <Route path = 'managemeta' element = {<ManageMeta/>}/>
      <Route path = 'privacy-policy' element = {<PrivacyPolicy/>}/>
      <Route path = "terms-of-service" element = {<TermsOfService/>} />
      </Routes>
    </BrowserRouter>
    </AuthContext.Provider>
    </>
  );
}

export default App;
