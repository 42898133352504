import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

export default function Redirect(){
    const [searchParams] = useSearchParams();
    const navigate =  useNavigate();

    useEffect(() => {
        const code = searchParams.get("code"); // "text"
        const state = searchParams.get("state"); // "text"
        if (!code || !state) return console.log("no code or state")
        const config = {
            method: 'get',
            url: document.MAIN_URL + `/authwithrbx/${code}/${state}`,
        }
        axios(config).then((res) => {
            console.log(res.data)
            if (res.data.success){ 
                localStorage.setItem('authed', true) 
                localStorage.setItem('authTime', Date.now())
                if (res.data.apikey!== 0) {
                    localStorage.setItem('key',res.data.apikey)
                    localStorage.setItem('permissions',JSON.stringify(res.data.permissions))
                    return navigate('/')
                } else {
                    
                }
             }
            if (!res.data.URL) return  navigate('/',)
            window.location.href = res.data.URL
            console.log("set auth url", res.data.URL)
        }
        ) .catch((err) => {
            console.log(err)
            alert('Could not authenticate with roblox..')
            navigate('/')
        }
        )
    }, [])


    return(
        <>
        <h1 className="hero-header mono">Redirecting, please do not refresh...</h1>
        </> 
    ) 
 
} 