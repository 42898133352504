import {
    Row,
    Col,
    Container,
    Navbar,
    NavbarBrand,
    Nav,
    Form,
    NavDropdown,
    Button,
    DropdownButton,
    Dropdown,
    ListGroup,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
    Image,
    Modal,
    Tabs,
    Tab,
    Table,
    Toast,
    ToastContainer,
    Stack,
    Figure,
    Card,
    FloatingLabel,
    Accordion,
    InputGroup,
    FormGroup,
  } from "react-bootstrap";
  
  import { AuthContext } from "../App";
  import React, { useContext, useEffect, useState, useRef } from "react";
  import { Label } from "@blueprintjs/core";
  import axios from "axios";
  import { Info, Trash } from "react-bootstrap-icons";
  import Line from "../components/Line";
  
  import ReactAnime from "react-animejs";
  import StylizedBG from "../components/StylizedBG";
  import Notification from "../components/Notification";
  import { createSearchParams, useNavigate } from "react-router-dom";
  import DashboardNU from "./DashboardNU";
  import NewApplication from "../components/NewApplication";
  import ManageApplicationsMeta from "../components/ManageApplicationsMeta";
import ReviewAppResponses from "../components/ReviewAppResponses";
import ViewEditApp from "../components/ViewEditApp";
  
  export default function ManageMeta() {
    const [orgData, setOrgData] = useState();
    const [show, setShow] = useState(false);
    const [viewApp, setViewApp] = useState();
    const [applicationId, setApplicationId] = useState();
    const [modalData, setModalData] = useState({
      header: "",
      body: "",
      onConfirm: () => {},
    });
    const [showToast, setShowToast] = useState(false);
    const [toastBody, setToastBody] = useState("");
    const navigate = useNavigate();
    const [appMode, setAppMode] = useState("manage"); // manage, edit, new
    const [reviewApp, setReviewApp] = useState();
    const [reviewResponse, setReviewResponse] = useState();
  
    const getData = () => {
      const config = {
        headers: {
          authorization: localStorage.getItem("key"),
        },
        url: document.MAIN_URL + "/getmeta",
      };
      axios(config).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setOrgData(res.data.org);
          if (reviewApp) {
            setReviewApp(orgData.applications[reviewApp.applicationMetaData.name])
            console.log("SET REVIEW APP")
          }
          console.log("set org data", res.data);
        } else if (res.data.message) {
          setToastBody(res.data.message);
          setShowToast(true);
        }
      });
    };
  
    useEffect(() => {
      getData();
      console.log(setShowToast,setToastBody,setOrgData,  setModalData, setShow,getData)
    }, []);
  
    const onSetPrimary = (app) => {
      const config = {
        method: "POST",
        url: document.MAIN_URL + `/setPrimaryApplication`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("key"),
        },
        data: {
          application: app,
        },
      };
      axios(config)
        .then((res) => {
          console.log(res.data);
          if (res.data.message) {
            setToastBody(res.data.message);
            setShowToast(true);
          }
          if (res.data.success) {
            //   getData();
          }
        })
        .catch((err) => {
          console.log(err);
          setToastBody(
            "Something went wrong while setting the primary application"
          );
          setShowToast(true);
        });
    };
  
    const object = {
      manage: (
        <ManageApplicationsMeta
          orgData={orgData}
          setToastBody = {setToastBody}
          setShowToast = {setShowToast}
          getData={getData}
          setModalData = {setModalData}
          setShow = {setShow}
          setReviewApp = {setReviewApp}
          setReviewResponse = {setReviewResponse}
          setViewApp={setViewApp}
          viewApp={viewApp}
          setApplicationId={setApplicationId}
        />
      ),
      new: (
        <NewApplication
          setModalData
          setShow
          setToastBody
          setShowToast
          setOrgData
          getData
          meta
        />
      ),
    };
  
    return (
      <>
        <StylizedBG canvas SPEED={0.007} style={{}} />
        <ReviewAppResponses setReviewApp = {setReviewApp} reviewApp={reviewApp} directory="meta" getData={getData} orgData={orgData} setShowToast={setShowToast} setToastBody={setToastBody}/>
        <ViewEditApp setViewApp={setViewApp} viewApp={viewApp} directory="meta" getData={getData} orgData={orgData} setShowToast={setShowToast} setToastBody={setToastBody} appId={applicationId}/>
        <br />
        <Notification
          show={showToast}
          notification={toastBody}
          classname={"backblur"}
          setShow={setShowToast}
        />
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="backblur"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalData.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalData.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                modalData.onConfirm();
                setShow(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
  
        <Container>
          <Row>
            <Col sm={12}>
              <Card className="app-builder square" >
                <Card.Body>
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="home" title="applications">
                      {" "}
                      &nbsp;
                      <Button
                        onClick={() => {
                          setAppMode("new");
                        }}
                      >
                        new application
                      </Button>
                      &nbsp;
                      <Button
                        onClick={() => {
                          setAppMode("manage");
                        }}
                      >
                        manage applications
                      </Button>
                      <br />
                      <br />
                      {object[appMode]}
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
     
          </Row>
        </Container>
      </>
    );
  }
  