import AuthWithRbx from "./AuthWithRbx";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
  Placeholder,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "../components/Line";

import ReactAnime from "react-animejs";
import StylizedBG from "../components/StylizedBG";
import Notification from "../components/Notification";
import { createSearchParams, useNavigate } from "react-router-dom";
import DashboardNU from "./DashboardNU";
import { set } from "animejs";

export default function Dashboard({ _apps, _userdata, _divisions,_metaapp }) {
  const [userData, setUserData] = useState(_userdata);
  const [divisions, setDivisions] = useState(_divisions);
  const [applications, setApplications] = useState(_apps);
  const [metaApp, setMetaApp] = useState(_metaapp);
  const [authed, setAuthed] = useState(
    localStorage.getItem("key") ? true : false
  );
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    header: "",
    body: "",
    onConfirm: () => {},
  });
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    console.log("_userdata", _userdata);

    if (!userData || !divisions || !applications) return getData();
  }, []);

  const getData = () => {
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/getSelf`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    const divConfig = {
      url: document.MAIN_URL + "/divisions",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    const metaConfig = {
      url: document.MAIN_URL + "/getPrimaryApplication",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };

    axios
      .all([axios(config), axios(divConfig), axios(metaConfig)])
      .then(
        axios.spread((...responses) => {
          const userData = responses[0].data;
          const divisions = responses[1].data;
          const metaApp = responses[2].data;
          const applicationsObj = {};
          const divisionsObj = {};
          divisions.map((division) => {
            divisionsObj[division.divisionName] = division;
            applicationsObj[division.divisionName] = division?.applications;
          });
          console.log(divisionsObj);
          console.log(applicationsObj);
          // if (!userData || !divisions || !applications) return
          setApplications(applicationsObj);
          setMetaApp([metaApp.application]);
          setDivisions(divisionsObj); // we were returned an array from the api, lets turn it into an object divName:divData

          setUserData(userData);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(()=>{
    setMetaApp(_metaapp)
  },[_metaapp])

  useEffect(() => {
    setDivisions(_divisions);
  }, [_divisions]);

  useEffect(() => {
    setApplications(_apps);
  }, [_apps]);

  useEffect(() => {
    setUserData(_userdata);
  }, [_userdata]);


  const leaveDivision = (division) => {
    const config = {
      method: "POST",
      url: document.MAIN_URL + `/leaveDivision`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
      },
    };
    // lets test our toast here

    axios(config).then((res) => {
      console.log(res.data);
      if (res.data.error) {
        return alert(res.data.error);
        window.location.reload();
      } // lets create a toastContainer for this
      if (res.data.message) {
        setToastBody(res.data.message);
        setShowToast(true);
      }
      if (res.data.success) {
        getData();
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.body}
    </Tooltip>
  );

  const onLogout = () => {
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/logout`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    axios(config).then((res) => {
      console.log(res.data);
      if (res.data.error) {
        return alert(res.data.error);
        window.location.reload();
      } // lets create a toastContainer for this
      if (res.data.message) {
        setToastBody(res.data.message);
        setShowToast(true);
      }
      if (res.data.success) {
        localStorage.removeItem("key");
        localStorage.removeItem("authed");
        localStorage.removeItem("authTime");
        window.location.reload();
      }
    }).catch(err=>{
      localStorage.removeItem("key");
      localStorage.removeItem("authed");
      localStorage.removeItem("authTime");
      window.location.reload();
    });
  };

  const onLeaveKaleida = () => {
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/leaveKaleida`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    axios(config).then((res) => {
      console.log(res.data);
      if (res.data.error) {
        return alert(res.data.error);
        window.location.reload();
      } // lets create a toastContainer for this
      if (res.data.message) {
        setToastBody(res.data.message);
        setShowToast(true);
      }
      if (res.data.success) {
        localStorage.removeItem("key");
        localStorage.removeItem("authed");
        localStorage.removeItem("authTime");
        localStorage.removeItem("userData");
        window.location.reload();
      }
    });
  };

  return (
    // we need a modal to confirm leaving a division and leaving kaleida, it needs to be dynamic to handle both actions
    <>
      {!authed ? (
        <DashboardNU />
      ) : (
        <>
          {/* <Anime initial={animData} > */}
          <StylizedBG canvas SPEED={0.007} />

          <br />
          <Notification
            show={showToast}
            notification={toastBody}
            classname={"backblur"}
            setShow={setShowToast}
          />
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="backblur"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalData.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalData.body}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  modalData.onConfirm();
                  setShow(false);
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          <Container className="">
            <Row>
              <Col sm={3}>
                <h3 className="mbm-dif">Dashboard</h3>

                <Card className="noborder backblur">
                  <Card.Header className="noborder white">
                    <h3>
                    {!userData ? (<> <Placeholder animation="wave">  <Placeholder  xs={7} />  </Placeholder></>): <>
                      Welcome{" "}
                      {userData?.userData.name.charAt(0).toUpperCase() +
                        userData?.userData.name.slice(1)}
                        </>}
                    </h3>
                  </Card.Header>

                  <Card.Body className="noborder mbm-dif">
                    <Card.Text>
                      <Stack direction="horizontal" gap={3}>
                        <span className="white smallcaps mono">
                          <Stack direction="vertical" >
                          user{" "}
                          {!userData ? (<> Loading... </>):
                          <span className="spec-disp">{userData?.docId}</span>}
                          </Stack>
                        </span>
                        <span className="white smallcaps mono">
                        <Stack direction="vertical" >
                          rank{" "}
                          {!userData ? (<> Loading...</>):
                          <span className="spec-disp">{userData?.rank}</span>
                      }      </Stack>
                        </span>
                      </Stack>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="noborder backblur">
                  <Card.Header className="noborder white ">
                    <Button
                      variant="outline-danger"
                      size={12}
                      style={{ width: "100%", height: "40px" }}
                      onClick={onLogout}
                    >
                      logout{" "}
                    </Button>
                  </Card.Header>
                </Card>
                <Card className="noborder backblur">
                  <Card.Header className="noborder white">
                    <h3>Divisions</h3>
                  </Card.Header>

                  <Card.Body className="noborder mbm-dif backblur">
                    <Card.Text>
                      <Stack direction="vertical" gap={3}>
                        {userData
                          ? Object.keys(userData.divisions).map(
                              (division, index) => {
                                return (
                                  <>
                                    <Stack direction="horizontal" gap={3}>
                                      <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip({
                                          body: `Leave ${division}`,
                                        })}
                                      >
                                        <Trash
                                          className="button-delete"
                                          onClick={(e) => {
                                            // we handle our modal data here
                                            setModalData({
                                              header: `Leave ${division}`,
                                              body: `Are you sure you want to leave ${division}?`,
                                              onConfirm: () => {
                                                leaveDivision(division);
                                              },
                                            });
                                            setShow(true);
                                          }}
                                        />
                                      </OverlayTrigger>
                                      <span className="white smallcaps mono">
                                        {division.toLowerCase()}
                                        {divisions[division]
                                          ? ` - ${
                                              divisions[division].roles[
                                                userData.divisions[division]
                                              ]?.name
                                            }`
                                          : null}
                                      </span>
                                    </Stack>
                                  </>
                                );
                              }
                            )
                          : null}
                      </Stack>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="noborder backblur">
                  <Card.Header className="noborder white ">
                    <Button
                      variant="outline-danger"
                      size={12}
                      style={{ width: "100%", height: "40px" }}
                      onClick={(e) => {
                        setShow(true);
                        setModalData({
                          header: `Leave Kaleida`,
                          body: `Are you sure you want to leave Kaleida? All data will be deleted. This cannot be undone.`,
                          onConfirm: () => {
                            onLeaveKaleida();
                          },
                        });
                      }}
                    >
                      leave kaleida
                    </Button>
                  </Card.Header>
                </Card>
              </Col>
              <Col>
                {/* we will now setup applications so users can join a division..*/}
                <h3 className="mbm-dif">Applications</h3>
                <Card className="noborder backblur">
                  <Card.Header className="noborder white">
                    <h3>
                      {Number(userData?.rank) > 0
                        ? "Join a division"
                        : "Join Kaleida"}
                    </h3>
                  </Card.Header>
                  <Card.Body className="noborder mbm-dif backblur scroll">
                    <Card.Text>
                      <Stack direction="vertical" gap={3}>
                        {Number(userData?.rank) > 0 ? (
                          <>
                            {applications
                              ? Object.keys(applications)?.map(
                                  (division, index) => {
                                    return divisions[division]
                                      .primaryApplication ? (
                                      <>
                                       
                                        <Stack direction="vertical" gap={3}>
                                          <Stack direction="horizontal" gap={3}>
                                            <span className="white smallcaps mono">
                                              {division.toLowerCase()} {">"}{" "}
                                              <span className="white smallcaps mono spec-disp">
                                                {
                                                  applications[division][
                                                    divisions[division]
                                                      .primaryApplication
                                                  ].applicationMetaData.name
                                                }
                                              </span>
                                            </span>
                                          </Stack>
                                          <span className="white smallcaps mono">
                                            description
                                          </span>
                                          <span className="white mono small">
                                            {
                                              applications[division][
                                                divisions[division]
                                                  .primaryApplication
                                              ].applicationMetaData.description
                                            }
                                          </span>
                                          <Button
                            
                                            onClick={() => {
                                              navigate({
                                                pathname: `/apply`,
                                                search: createSearchParams({
                                                  division: division,
                                                  application:
                                                    divisions[division]
                                                      .primaryApplication,
                                                }).toString(),
                                              });
                                            }}
                                          >
                                            apply
                                          </Button>
                                          <Line />
                                        </Stack>
                                      </>
                                    ) : null;
                                  }
                                )
                              : null}
                          </>
                        ) : (
                          <>
                          {metaApp?.length > 0 ? Object.keys(metaApp)?.map((division, index) =>{ 
                            console.log(metaApp[index],metaApp)
                            return(
                            <>
                              <Line />
                              <Stack direction="vertical" gap={3}>
                                <Stack direction="horizontal" gap={3}>
                                  <span className="white smallcaps mono">
                                    {'kaleida'} {">"}{" "}
                                    <span className="white smallcaps mono spec-disp">
                                      {
                                        metaApp[index].applicationMetaData
                                          .name
                                      }
                                    </span>
                                  </span>
                                </Stack>
                                <span className="white smallcaps mono">
                                  description
                                </span>
                                <span className="white mono small">
                                  {
                                    metaApp[index].applicationMetaData
                                      .description
                                  }
                                </span>
                                <Button
                                              disabled = {metaApp[index].submitted}
                                  onClick={() => {
                                    navigate({
                                      pathname: `/apply`,
                                      search: createSearchParams({
                                        division: 'meta',
                                        application: 'primary'
                                      }).toString(),
                                    });
                                  }}
                                >
                                 {!metaApp[index].submitted ? "apply":"applied"}
                                </Button>
                                <Line />
                              </Stack>
                            </>
                          )}
                            ):null}
                          </>
                        )}
                      </Stack>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
