import {
    Row,
    Col,
    Container,
    Navbar,
    NavbarBrand,
    Nav,
    Form,
    NavDropdown,
    Button,
    DropdownButton,
    Dropdown,
    ListGroup,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
    Image,
    Modal,
    Tabs,
    Tab, 
    Table,
    Toast,
    ToastContainer,
    Stack,
    Figure,
    Card,
    FloatingLabel,
    Accordion,
    InputGroup,     
    FormGroup,
  } from "react-bootstrap";
  import axios from "axios";
    import { useState,useEffect } from "react";

export default function ManageApplicationsMeta ({orgData,setShowToast,setToastBody,setOrgData,  setModalData, setShow,getData,setReviewApp,setReviewResponse,viewApp,setViewApp,setApplicationId}) {
    const [applicationDeleting, setApplicationDeleting] = useState();
  

    useEffect (() => {
        console.log(orgData)
      getData()
      console.log(setShowToast,setToastBody,setOrgData,  setModalData, setShow,getData)
        },[]) 

    const onSetPrimary = (app) => {
        const config = {
          method: "POST",
          url: document.MAIN_URL + `/setPrimaryApplication`,
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("key"),
          },
          data: {
            application: app,
          },
        };
        axios(config)
          .then((res) => {
            console.log(res.data);
            if (res.data.message) {
              setToastBody(res.data.message);
              setShowToast(true);
            }
            if (res.data.success) {
             getData();
            }
          })
          .catch((err) => {
            console.log(err);
            setToastBody(
              "Something went wrong while setting the primary application" 
            );
            setShowToast(true);
          });
      };

  

        useEffect (() => {}, [orgData])
    return ( (<> <Table
        className="table-variant-1 backblur"
        striped
        bordered
        hover
      >
        <thead>
          <tr>
            <th>Application ID</th>
            <th>Applicants</th>
            <th>Primary</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orgData
            ? Object.keys(orgData.applications).map((app) => {
                return (
                  <tr>
                    <td className="log-data">{app}</td>
                    <td className="log-data">
                      {orgData.applications[app].responses
                        ? Object.keys(orgData.applications[app].responses).length
                        : 0}
                    </td>
                    <td className="log-data">
                      {orgData.primaryApplication
                        ? orgData.primaryApplication === app
                          ? "true"
                          : "false"
                        : "false"}
                    </td>
                    <td className="log-data">
                      <Stack direction="horizontal" gap={1}>
                        <Button variant="outline-primary" onClick={()=>{
                            setApplicationId(app)
                            setViewApp(orgData.applications[app])
                        }}>
                          edit | view
                        </Button>
                        <Button
                          variant="outline-danger"
                          name={app}
                          onClick={() => {
                            setModalData({
                              header: "Delete Application",
                              body: "Are you sure you want to delete this application? This action cannot be undone.",
                              onConfirm: () => {
                                const config = {
                                    method: "POST",
                                  headers: {
                                    authorization:
                                      localStorage.getItem("key"),
                                  },
                                  url:
                                    document.MAIN_URL +
                                    "/deletemetaapplication",
                                  data: {
                                    application: app,
                                  },
                                };
                                axios(config).then((res) => {
                                  console.log(res.data);
                                  if (res.data.success) {
                                    setToastBody(res.data.message);
                                    setShowToast(true);
                                    // setOrgData(res.data);
                                    console.log(
                                      "set org data",
                                      res.data
                                    );
                                    getData();
                                  } else if (res.data.message) {
                                    setToastBody(res.data.message);
                                    setShowToast(true);
                                  }
                                }).catch((err) => {
                                    console.log(err)});
                              },
                            });
                            setShow(true);
                            setApplicationDeleting(app);
                          }}
                        >
                          delete
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={(e) => onSetPrimary(app)}
                        >
                          set as primary
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={(e) => setReviewApp(orgData.applications[app])}
                        >
                          review
                        </Button>
                      </Stack>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table></>))
}