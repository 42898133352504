import { useNavigate } from "react-router-dom";
import Line from "../components/Line";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import AuthTimer from "../components/AuthTimer";
import AuthWithRbx from "./AuthWithRbx";

export default function Header() {
  const navigate = useNavigate();
  const [
    authKey,
    setAuthKey,
    authed,
    setAuthed,
    timeCreated,
    setTimeCreated,
    modalShow,
    setModalShow,
    modalData,
    setModalData,
  ] = useContext(AuthContext);
  setAuthed(localStorage.getItem("key") ? true : false);
  const [authTime, setAuthTime] = useState(
    new Date(Date.parse(localStorage.getItem("authTime")))
  );
  const [gradientStyle, setGradientStyle] = useState({
    filter: "hue-rotate(0deg)",
  });
  const [userData, setUserData] = useState(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : {}
  );

  useEffect(() => {
    if (
      !localStorage.getItem("authenticated") ||
      localStorage.getItem("authenticated") === "false"
    ) {
      setAuthed(false);
    }
    setUserData(JSON.parse(localStorage.getItem("userData")));
    console.log(userData.rank)
  }, []);

  function getEpochTime() {
    const date = new Date();
    return date.getTime() / 1000;
  }

  const onClick = (e) => {
    navigate(e.target.id);
  };
  const onEnterAuthInput = (e) => {
    setAuthKey(e.target.value);
  };

  const onAuthKeySubmit = async (e) => {
    e.preventDefault();
    console.log(authed, "!!!!!!!!!");
    const config = {
      method: "GET",
      url: document.MAIN_URL + `/verifyKey/${authKey}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log("???");
    const result = await axios(config).then((res) => {
      console.log(res);
      if (res.data === "invalid-code") {
        setModalData({
          header: "Server Error",
          bodyText: "Invalid authentication",
        });
        setModalShow(true);
        return;
      } else if (res.data.time) {
        setModalData({
          header: "Authorization Code Set",
          bodyText: `Active for ${Math.floor(
            1800 / 60 - (getEpochTime() - res.data.time) / 60
          )} minutes.`,
        });
        setModalShow(true);
        setAuthed(true);
        localStorage.setItem("key", authKey);
        localStorage.setItem("authenticated", true);
        const time = new Date();
        const timeDif = getEpochTime() - res.data.time;
        time.setSeconds(time.getSeconds() + (1800 - timeDif));
        localStorage.setItem("authTime", time.toISOString());
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        setAuthTime(time);
      }
      // const data = res.data
    });
  };
  // id for label or non button / value for button
  return (
    <>
      {" "}
      {authed ? (
        <>
          <div className="grad-bg" style={gradientStyle}>
            <div className="bd-filt">
              <br />
              <Container>
                <ol>
                  <li>
                    <ul className="navbar-top">
                      {authed ? (
                        <>
                          <li>
                            <label
                              className="nav-button noround noborder"
                              id="/"
                              onClick={onClick}
                            >
                              dashboard
                            </label>
                          </li>
                          {Number(userData?.rank) > 0 ? (
                            <>
                              {userData ? (
                                Number(userData.rank) >= 5 ? (
                                  <>
                                    {" "}
                                    <li>
                                      <label
                                        className="nav-button noround noborder"
                                        id="/addprofile"
                                        onClick={onClick}
                                      >
                                        add profile
                                      </label>
                                    </li>
                                    <li>
                                      <label
                                        className="nav-button noround noborder"
                                        id="/managemembers"
                                        onClick={onClick}
                                      >
                                        manage members
                                      </label>
                                    </li>
                                    {Number(userData.rank) >= 7 ? (
                                      <>
                                        {" "}
                                        <li tabIndex={10}>
                                          <label
                                            className="nav-button noround noborder"
                                            id="/managemeta"
                                            onClick={onClick}
                                          >
                                            kaleida
                                          </label>
                                        </li>
                                      </>
                                    ) : null}
                                    <></>
                                  </>
                                ) : null
                              ) : null}
                              <li>
                                <label
                                  className="nav-button noround noborder"
                                  id="/managedivisions"
                                  onClick={onClick}
                                >
                                  manage divisions
                                </label>
                              </li>

                              <li>
                                <label
                                  className="nav-button noround noborder"
                                  id="/logs"
                                  onClick={onClick}
                                >
                                  logs
                                </label>
                              </li>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <></>
                      )}
                      {!authed ? (
                        <>
                          <li> </li>
                        </>
                      ) : (
                        <li>
                          {/* <AuthTimer
                            expiryTimestamp={authTime}
                            setStyle={setGradientStyle}
                          /> */}
                        </li>
                      )}
                      {/* <p className="nav-data">username</p> */}
                      {/* <li><p className="nav-data">username</p></li> */}
                    </ul>
                  </li>
                </ol>
                <br />
                {/* <Line
        backgroundColor={"rgba(255, 255, 255,1)"}
        height={"5"}
        width={"100%"}
        color={"white"}
        position={"absolute"}
      /> */}
              </Container>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
//"439644067484401664"
//  47442224
{
  /*
      <li>
                  {" "}
                  <p className="nav-data">authentication key:</p>
                </li>
                <li> 
<InputGroup
isInvalid={true}
className="mb-3"
onChange={onEnterAuthInput}
>
<Form.Control
  aria-label="Text input with dropdown button"
  value={authKey}
/>
<Button
  variant="outline-secondary"
  id="button-addon1"
  onClick={onAuthKeySubmit}
>
  {`set key`}
</Button>
</InputGroup> */
}
