import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Offcanvas,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import ReactJson from "react-json-view";

import { Trash } from "react-bootstrap-icons";
import StylizedBG from "../components/StylizedBG";
import { useNavigate } from "react-router-dom";

export default function Logs() {
  const [logs, setLogs] = useState([]);
  const [logElements, setLogElements] = useState([]);
  const [show, setShow] = useState(false);
  const [currentDiff, setCurrentDiff] = useState({});
  const [selectedAction, setSelectedAction] = useState();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleColors = () => {
    const elements = document.getElementsByClassName("variable-value");
    const elementsString = document.getElementsByClassName("string-value");
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].innerHTML.includes(`"deleted"`)) {
        elements[i].className = "deleted";
      } else if (elements[i].innerHTML.includes(`created`)) {
        elements[i].className = "created";
      } else if (elements[i].innerHTML.includes(`updated`)) {
        elements[i].className = "updated";
      } else if (elements[i].innerHTML.includes(`unchanged`)) {
        elements[i].className = "unchanged";
      }
    }

    for (let i = 0; i < elementsString.length; i++) {
      if (elementsString[i].innerHTML.includes(`"deleted"`)) {
        elementsString[i].className = "deleted";
      } else if (elementsString[i].innerHTML.includes(`created`)) {
        elementsString[i].className = "created";
      } else if (elementsString[i].innerHTML.includes(`updated`)) {
        elementsString[i].className = "updated";
      } else if (elementsString[i].innerHTML.includes(`unchanged`)) {
        elementsString[i].className = "unchanged";
      }
    }
  };

  useEffect(() => {
    console.log(document.MAIN_URL)
    const config = {
      method: "GET",
      url: document.MAIN_URL+`/getAllLogs`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
    };
    axios(config).then((res) => {
      if (res.data) {
        console.log(res.data);
        setLogs(res.data);
        setLogElements(
          res.data.map((log) => {
            return (
              <tr>
                <td className="log-data">
                  <small>{new Date(log.timestamp).toLocaleString()}</small>
                </td>
                <td className="log-data">{log.logType}</td>
                <td className="log-data">{log.operator.name}</td>
                <td className="log-data">
                  {log.division ? log.division.toLowerCase()+" :: " : null}
                  {log.logType !== "create"
                    ? log.userChanged || log.changelog || log.division
                      ? log.userChanged
                        ? log.userChanged.name
                        : log.changelog
                        ? log.changelog.changed.length + " users modified"
                        : null
                      : "n/a"
                    : log.newData.userData.name}
                </td>
                <td>
                  {log.diff || log.logType === "create" ? (
                    <>
                      {" "}
                      <span
                        className="Button log-data log-button"
                        variant="primary"
                        onClick={() => {
                          setSelectedAction(log.logType);
                          handleShow();
                          if (log.logType === "create") {
                            setCurrentDiff(log.newData);
                          } else {
                            const diffFormatted = { ...log.diff };
                            if (diffFormatted.created) {
                              delete diffFormatted.created;
                            }
                            setCurrentDiff(log.diff);
                          }
                        }}
                      >
                        {log.logType === "create" ? "view" : "diff"}
                      </span>
                    </>
                  ) : null}
                </td>
              </tr>
            );
          })
        );
      }
    }).catch(err=>{
      console.log(err)
      navigate("/")
    });
    console.log(document.getElementsByClassName("variable-value"));
  }, []);

  return (
    <StylizedBG>
      <Offcanvas show={show} onHide={handleClose} className={"diff-viewer"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <span className="find-user-property-3">diff data</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ReactJson
            src={currentDiff}
            theme={"tube"}
            displayDataTypes={false}
            iconStyle="circle"
            quotesOnKeys={false}
            sortKeys={true}
            name={"root"}
            onSelect={(e) => {
              handleColors();
            }}
            shouldCollapse={(field) => {
              console.log(field);
              let collapse = true;
              if (typeof field.src === "object") {
                let foundItem = false;
                collapse = false;
                const keys = Object.keys(field.src);
                keys.forEach((key) => {
                  const ind = field.src[key];
                  if (ind.type) {
                    if (
                      ind.type === "created" ||
                      ind.type === "deleted" ||
                      ind.type === "updated"
                    ) {
                      foundItem = true;
                      console.log("found item", ind);
                    }
                  }
                });
                if (foundItem) {
                  console.log("RETURNING FALSE BECAUSE OF FOUND ITEM", field);
                  return false;
                }
              }
              if (selectedAction.includes("role")) {
                if (field.name.includes("role")) {
                  return false;
                }
              }
              if (
                field.name === "created" ||
                field.name === "deleted" ||
                field.name === "updated" ||
                field.name === "root"
              ) {
                return false;
              }
              if (field.src.type) {
                if (
                  field.src.type === "created" ||
                  field.src.type === "deleted" ||
                  field.src.type === "updated"
                ) {
                  return false;
                } else {
                  return true;
                }
              }

              if (Array.isArray(field.src)) {
                field.src.forEach((element) => {
                  if (!element.type) {
                    console.log("no type", element);
                  } else if (
                    element.type === "created" ||
                    element.type === "deleted" ||
                    element.type === "updated"
                  ) {
                    collapse = false;
                  }
                });
              }
              return collapse;
            }}
          />
          <Button
            className="Button noround btn btn-danger-outline"
            variant="primary"
            onClick={() => {
              handleColors();
            }}
          >
            color
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
      <Container className="logs">
        <Table striped bordered hover size="sm" className="table-variant-1 backblur">
          <thead>
            <tr>
              <th>timestamp</th>
              <th>action</th>
              <th>operator</th>
              <th>data-label changed</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{logElements}</tbody>
        </Table>
      </Container>

    </StylizedBG>
  );
}
