import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState } from "react";

import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import DeleteRoleModal from "./DeleteRoleModal";
import SortBar from "./SortBar";
import { useNavigate, Navigate } from "react-router-dom";
import NewApplication from "./NewApplication";
import ManageApplications from "./ManageApplications";

// import { ReactFormBuilder } from 'react-form-builder2';
// import 'react-form-builder2/dist/app.css';

export default function DivisionTab({ division, userData }) {
  const [users, setUsers] = useState([]);
  const [divData, setDivData] = useState();
  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState();
  const [memberData, setMemberData] = useState();
  const [applicationsViewMode, setApplicationsViewMode] = useState("");

  const [filter, setFilter] = useState();
  const [sort, setSort] = useState();
  const [filtered, setFiltered] = useState();
  const [sorted, setSorted] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [deleteUser, setDeleteUser] = useState(false);
  const [deleteRole, setDeleteRole] = useState(false);
  const [roleModalShow, setRoleModalShow] = useState(false);
  const [fallbackRole, setFallbackRole] = useState();

  const [confirmShow, setConfirmShow] = useState(false);
  const handleConfirmClose = () => setConfirmShow(false);
  const handleConfirmShow = () => setConfirmShow(true);
  const [confirmBody, setConfirmBody] = useState("");

  const [notifShow, setNotifShow] = useState(false);
  const [def, _] = useState({
    roleName: "",
    canRank: false,
    canManage: false,
    superUser: false,
    roleIndex: -1,
  });
  const [formData, setFormData] = useState({
    roleName: "",
    canRank: false,
    canManage: false,
    superUser: false,
    roleIndex: -1,
  });
  const [roleIndexes, setRoleIndexes] = useState([]);
  const [index, setIndex] = useState(0);

  const [userElements, setUserElements] = useState();
  const navigate = useNavigate();

  const onChange = (event) => {
    let { name, value } = event.target;
   

    setFormData((prevData) => ({
      ...prevData, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  const onChecked = (event) => {
    let { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData, // shallow copy all previous state
      [name]: checked, // update specific key/value
    }));
  };

  useEffect(() => {
   
    getData();
  }, []);

  const getData = async () => {
    setUsers([]);
    const authKey = localStorage.getItem("key");
    const userConfig = {
      url: document.MAIN_URL+"/getUsersByDivision",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: {
        division: division,
      },
    };
    const divConfig = {
      url: document.MAIN_URL+"/getDivision",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: {
        division: division,
      },
    };
    const permConfig = {
      url: document.MAIN_URL+"/getUserPermissions",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: {
        division: division,
        user: userData.docId,
      },
    };

    await axios(userConfig).then(async (res) => {
      setUsers(res.data);
     

      await axios(divConfig)
        .then(async (res) => {
          setDivData(res.data);
         
          await axios(permConfig).then((res) => {
            setPermissions(res.data);
           
            setLoading(false);
          });
        })
        .catch((err) => {
          setNotifShow(true);
          setNotification(err.message);
        });
    });
  };

  const editRole = (event) => {
    setShow(true);
  };

  const newRole = (event) => {
    setRankElements();
    setShow(true);
  };

  const setRankElements = () => {
    const ranks = [];
    const takenRanks = Object.keys(divData.roles);
    for (let i = 0; i < 10; i++) {
      const isDisabled =
        takenRanks.includes((i + 1).toString()) || i + 1 >= permissions.maxRank;
      ranks[i] = (
        <>
          {" "}
          <NavDropdown.Item
            href={"#" + (i + 1)}
            key={i}
            id={i + 1}
            // onClick={selectedQueryChanged}
            value={"rank"}
            disabled={isDisabled}
            name="roleIndex"
            onClick={(e) => {
              setRoleIndex(i + 1);
            }}
          >
            {i + 1}
          </NavDropdown.Item>
        </>
      );
    }
    setRoleIndexes(ranks);
  };

  const setRoleIndex = (value) => {
    setFormData((prevData) => {
      prevData.roleIndex = value;
     
      return prevData;
    });
    setIndex(value);
  };

  const onNewRole = async (event) => {
    const config = {
      url: document.MAIN_URL+"/addDivisionRole",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
        role: formData,
      },
    };
    await axios(config).then((res) => {
      console.log(res.data)

      if (res.data.success) {
        setShow(false);
        getData();
        setNotifShow(true);
        setNotification("Role added successfully!");
        setFormData(def);
      } else if (res.data.message && !res.data.success) {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    }).catch(console.log);
  };

  const onRoleDelete = async (event) => {
    const role = event.target.name;
   
    setDeleteRole(role);
    setRoleModalShow(true);
  };

  const onRoleDeleteConfirm = async (event) => {
    if (!deleteRole) return setRoleModalShow(false);
    if (!fallbackRole) return;
   
    const role = event.target.name;
    const config = {
      url: document.MAIN_URL+"/deleteDivisionRole",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
        role: deleteRole,
        fallbackRole,
      },
    };
    await axios(config).then((res) => {
      console.log(res.data)

      if (res.data.success) {
        setRoleModalShow(false);
        getData();
        setNotifShow(true);
        setNotification("Role deleted successfully!");
        setFormData(def);
      } else if (res.data.message && !res.data.success) {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    }).catch(console.log);
  };

  const onPlayerRoleChange = async (event) => {
    const config = {};
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const onUserRoleChange = async (role, user) => {
    const config = {
      url: document.MAIN_URL+"/updateUserRole",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        division: division,
        role,
        user,
      },
    };
    await axios(config).then((res) => {
      console.log(res.data)
      if (res.data.success) {
        setShow(false);
        getData();
        setNotifShow(true);
        setNotification("User role changed successfully!");
        setFormData(def);
      } else if (res.data.message && !res.data.success) {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    }).catch(console.log);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.body}
    </Tooltip>
  );

  const onUserDelete = async (user) => {
    setConfirmBody(`Exile ${user.userData.name} from ${division}?`);
    setConfirmShow(true);
    user = user.docId;
    setDeleteUser(user);
  };

  const onUserDeleteConfirm = async () => {
    if (!deleteUser) return setConfirmShow(false);
   
    const config = {
      url: document.MAIN_URL+"/deleteUserFromDivision",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("key"),
      },
      data: {
        user: deleteUser,
        division,
      },
    };
    setDeleteUser();
    axios(config).then((res) => {
      console.log(res.data)
      if (res.data.success) {
        getData();
        setNotifShow(true);
        setNotification("User deleted successfully!");
      } else if (res.data.message && !res.data.success) {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    }).catch(err=>console.log(err));
    setConfirmShow(false);
  };

  useEffect(() => {
    if (!divData || !users) return;
    setUserElements();
  }, [users, filter, divData]);

  const createUserElements = () => {
    const usersFiltered = users.filter((user) => {
     
      if (!filter) return user;
      return filter.process(user);
    });
   
    const usersSorted = sort
      ? usersFiltered.sort((a, b) => sort.process(a, b))
      : usersFiltered;
   
    const elements = usersSorted.map((user, i) => {
      // sort here
     
      return (
        <tr key={user.userData.name}>
          <td className="log-data">{user.userData.name}</td>
          <td >
            <Dropdown
              onSelect={(e) => {
                onUserRoleChange(e, user);
              }}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {divData.roles[user.divisions[division]]?.name +
                  " : " +
                  user.divisions[division]}{" "}
                &nbsp;
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                {Object.keys(divData.roles).map((role, i) => {
                  return (
                    <Dropdown.Item
                      active={
                        Number(role) === user.divisions[division] ? true : false
                      }
                      disabled={
                        !permissions?.canRank ||
                        Number(role) >= permissions.maxRank ||
                        user.divisions[division] >= permissions.maxRank
                      }
                      eventKey={role}
                    >
                      {divData.roles[role].name + " : "}{" "}
                      <span className="find-user-property-3">{role}</span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td className="log-data">{user.rank}</td>
          <td className="log-data">
            {permissions?.canManage || permissions?.canRank ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({ body: "Remove User" })}
              >
                <Trash
                  size={25}
                  className="sprite"
                  onClick={(e) => onUserDelete(user)}
                />
              </OverlayTrigger>
            ) : null}
          </td>
        </tr>
      );
    });
    return elements;
  };

  const object = {
    NewApplication: <NewApplication division={division} divData={divData} forDivision divPermissions = {permissions} />,
    ManageApplications: <ManageApplications division={division} divData={divData} forDivision divPermissions = {permissions} getData={getData} />,
  };

  return (
    <>
      <ToastContainer
        className="p-3"
        position={"top-end"}
        style={{ zIndex: 9999999999999999 }}
      >
        <Toast
          onClose={() => setNotifShow(false)}
          show={notifShow}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Action Notification</strong>
            <small style={{ fontVariant: "small-caps" }}>kaleida</small>
          </Toast.Header>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      {divData ? (
        <DeleteRoleModal
          show={roleModalShow}
          setShow={setRoleModalShow}
          permissions={permissions}
          divData={divData}
          division={division}
          deleteRole={deleteRole}
          setFallbackRole={setFallbackRole}
          onConfirm={onRoleDeleteConfirm}
        />
      ) : null}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Untitled Role"
                name="roleName"
                onChange={onChange}
              />
            </Form.Group>
            <Form.Label>Permissions</Form.Label>
            <Stack direction="horizontal" gap={0.01}>
              <Form.Switch
                type="switch"
                as={"input"}
                name="canRank"
                onChange={onChecked}
                label="&nbsp; Can Rank Users"
              />
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({
                  body: "Determines if users in this role can set the role of users under a lower role index or review & accept applications.",
                })}
              >
                <Info size={25} />
              </OverlayTrigger>
            </Stack>

            <Stack direction="horizontal" gap={0.01}>
              <Form.Switch
                type="switch"
                as={"input"}
                name="canManage"
                onChange={onChecked}
                label=" &nbsp; Can Manage Division"
              />
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({
                  body: "Determines if users in this role can manage or create roles with a lower role index [create, update, delete].",
                })}
              >
                <Info size={25} />
              </OverlayTrigger>
            </Stack>

            <Stack direction="horizontal" gap={0.01}>
              <Form.Switch
                type="switch"
                as={"input"}
                name="manageApplications"
                onChange={onChecked}
                label=" &nbsp; Can Manage Applications"
              />
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({
                  body: "Determines if users in this role can create edit or delete applications.",
                })}
              >
                <Info size={25} />
              </OverlayTrigger>
            </Stack>
            <NavDropdown
              title={`Role Index: ${index}`}
              id="navbarScrollingDropdown"
              name="roleIndex"
              label={formData.roleIndex}
            >
              {roleIndexes}
            </NavDropdown>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              handleClose(e);
              setFormData(def);
            }}
          >
            Discard Changes
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onNewRole();
              setFormData((oldData) => {
               
                return oldData;
              });
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmShow}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onUserDeleteConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title={(<span className="spec-disp">{division?.toLowerCase()}</span>)}>
          <span className="nav-data">{users.length} members</span>
        </Tab>
        <Tab eventKey="profile" title={(<span className="spec-disp">members</span>)} className="app-builder">
          {divData ? (
            <SortBar
              divData={divData}
              setFilter={setFilter}
              setSort={setSort}
              setSorted={setSorted}
              setFiltered={setFiltered}
              division={division}
            />
          ) : null}
          <Table striped bordered hover size="sm" className="table-variant-1 backblur">
            <thead>
              <tr>
                <th>Member</th>
                <th>Role</th>
                <th>Global Clearance</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {divData
                ? !sort
                  ? createUserElements()
                  : createUserElements()
                : null}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="contact" title={(<span className="spec-disp">roles</span>)} >
          <Button
            variant="outline-primary"
            disabled={!permissions?.canManage}
            onClick={newRole}
            size="sm"
          >
            new role
          </Button>
          <br />
          &nbsp;
          <Table striped bordered hover size="sm" className="table-variant-1 backblur ">
            <thead>
              <tr>
                <th>#</th>
                <th>Role Name</th>
                <th>Role Users</th>
                <th>Manage Roles</th>
                <th>Manage Applications</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {divData ? (
                Object.keys(divData.roles).map((role, i) => {
                  return (
                    <tr key={i}>
                      <td className="log-data">{role}</td>
                      <td  className="log-data">{divData.roles[role].name}</td>
                      <td colSpan={1}  className="log-data">
                        {divData.roles[role]?.canRank ? "true" : "false"}
                      </td>
                      <td colSpan={1} className="log-data">
                        {divData.roles[role]?.canManage ? "true" : "false"}
                      </td>
                      <td colSpan={1} className="log-data">
                        {divData.roles[role]?.manageApplications
                          ? "true"
                          : "false"}
                      </td>
                      <td>
                        <Button
                          variant="primary-outline"
                          className="outline Button"
                          disabled={!permissions?.canManage}
                          name={role}
                        >
                          <b>edit</b>
                        </Button>
                        &nbsp;
                        <Button
                          variant="danger-outline"
                          className="Button-1 noround"
                          disabled={!permissions?.canManage}
                          name={role}
                          onClick={onRoleDelete}
                        >
                          delete
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="apps" title={(<span className="spec-disp">applications</span>)} >
          <Stack direction="horizontal" gap={1}>
            <Button
              variant="outline-success"
              disabled={
                !permissions?.manageApplications && !permissions?.superUser
              }
              onClick={() => {
                setApplicationsViewMode("NewApplication");
              }}
              size="sm"
            >
              new application
            </Button>
            <Button
              variant="outline-primary"
              disabled={
                !permissions?.manageApplications && !permissions?.superUser
              }
              onClick={() => {
                setApplicationsViewMode("ManageApplications");
              }}
              size="sm"
            >
              manage applications
            </Button>
          </Stack>
          {object[applicationsViewMode]}
        </Tab>
      </Tabs>
    </>
  );
}
 