import AuthWithRbx from "./AuthWithRbx";
import {
  Row,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Tabs,
  Tab,
  Table,
  Toast,
  ToastContainer,
  Stack,
  Figure,
  Card,
  FloatingLabel,
  Accordion,
  InputGroup,
  FormGroup,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { Info, Trash } from "react-bootstrap-icons";
import Line from "../components/Line";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <p className="mbm-dif mono spec-disp">
              <Stack direction="vertical" gap={3}>
                <br />
                <h3>
                  <b className="ai">
                    <i>Privacy Policy</i>
                  </b>{" "}
                </h3>
                <br />
                <i className="review">Last updated: July 24, 2023</i>
                <br />
                <b>
                  This Privacy Policy explains how Kaleida ("we," "us," or
                  "our") collects, uses, and discloses your information when you
                  use the Kaleida app (the "Service"). By using the Service, you
                  agree to the terms of this Privacy Policy.
                </b>
                <br />
                <br />
                <h4>Interpretation and Definitions</h4>
                <b>Definitions</b>
                <li>
                  "Account" means a unique account created to access our
                  Service.
                </li>
                <li>
                  "Application" refers to Kaleida, the software program provided
                  by the Company.
                </li>
                <li>
                  "Company" refers to Kaleida, the entity administering and
                  managing the software program.
                </li>
                <li>
                  "Personal Data" is any information related to an identified or
                  identifiable individual.
                </li>
                <li>"Service" refers to the Kaleida app.</li>
                <li>
                  "Third-party Social Media Service" refers to Roblox, the
                  platform used for signing in to our Service.
                </li>
                <br />
                <br />
                <h4>Collecting and Using Your Personal Data</h4>
                <br />
                <br />
                <h4>Personal Data Collected</h4>
                <li>
                  While using our Service, we may collect certain personally
                  identifiable information, such as usage data and your device's
                  IP address. If you sign in using Roblox, we may collect your
                  Roblox data, such as your username and user ID associated with
                  your Roblox account.
                </li>
                <br />
                <h4>Usage Data</h4>
                <li>
                  We automatically collect usage data, such as your IP address,
                  browser type, pages visited, and the time and date of your
                  visits. When accessing the Service through a mobile device, we
                  may collect additional information like your mobile device
                  type and operating system.
                </li>
                <li>
                  <b className="accept">
                    <i>
                      Personnally identifying information such as IP addresses
                      are <b>hashed and anonymized</b> before being stored in
                      our database. This process is non-reversible for your
                      security. <br /> IP addresses shall not be retained at the
                      time of user sign-up; however, they may be collected and
                      stored solely during specific administrative actions
                      undertaken to prevent abuse and unauthorized use of this
                      application.
                    </i>
                  </b>
                </li>
                <br />
                <br />
                <h4>Information Collected while Using the Application</h4>
                With your prior permission, we may collect location information
                to provide certain features of our Service. Cookies and Tracking
                Technologies
                <br />
                <br />
                We don't use cookies, but we may use similar tracking
                technologies, like beacons and scripts, to analyze usage and
                improve the Service.
                <br />
                <br />
                <h4>Use of Your Personal Data</h4>
                <br />
                <b>
                  We use your Personal Data for various purposes, including:
                </b>
                <li>Providing and maintaining the Service.</li>
                <li>Managing your account and registration.</li>
                <li>
                  Contacting you regarding updates or informative communications
                  related to the Service.
                </li>
                <li>
                  Sending you news, special offers, and information about our
                  products and services.
                </li>
                <br />
                <b>
                  We may share your Personal Data in specific situations,
                  including:
                </b>
                <li>
                  With service providers to monitor and analyze the use of our
                  Service.
                </li>
                <li>
                  In connection with a business transfer or acquisition of our
                  company.
                </li>
                <li>
                  With our affiliates and business partners to offer certain
                  products or services.
                </li>
                <li>
                  With other users when you interact with them on the Service.
                </li>
                <br />
                <h4>Retention of Your Personal Data</h4>
                <li>
                  We will retain your Personal Data only as long as necessary
                  for the purposes outlined in this Privacy Policy.
                </li>
                <li>
                  Usage Data may be retained for internal analysis purposes but
                  will generally be kept for shorter periods.
                </li>
                <br />
                <h4>Transfer of Your Personal Data</h4>
                <li>
                  Your Personal Data may be processed in locations outside of
                  your jurisdiction. By using our Service, you consent to such
                  transfers.
                </li>
                <br />
                <h4>Delete Your Personal Data</h4>
                <li>
                  You have the right to delete or request assistance in deleting
                  your Personal Data.
                </li>
                <br />
                <h4>Disclosure of Your Personal Data</h4>
                <li>
                  We may disclose your Personal Data in specific situations,
                  including business transactions and compliance with legal
                  obligations. Security of Your Personal Data
                </li>
                <li>
                  While we strive to use commercially acceptable means to
                  protect your Personal Data, no method of transmission or
                  storage is 100% secure.
                </li>
                <br />
                <h4>Children's Privacy</h4>
                <li>
                  Our Service is not intended for users under the age of 13, and
                  we do not knowingly collect personally identifiable
                  information from anyone under 13.
                </li>
                <h4>Links to Other Websites</h4>
                <li>
                  Our Service may contain links to third-party websites. We
                  recommend reviewing the Privacy Policy of any site you visit.
                </li>
                <h4>Changes to this Privacy Policy</h4>
                <li>
                  We may update this Privacy Policy, and we will notify you of
                  any changes.
                </li>
                <h4>Contact Us </h4>
                <br />
                If you have any questions about this Privacy Policy, please
                contact us at: <br />
                <span className="accept">https://discord.gg/qnZpB9zvgm</span>or
                <span className="accept">support@kaleida.net</span>
                <Link to="/privacy-policy">
                  Our terms of service can be found at
                  https://kaleida.net/terms-of-service.
                </Link>
              </Stack>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
