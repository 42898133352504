import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  Form,
  NavDropdown,
  Button,
  DropdownButton,
  Dropdown,
  ListGroup,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image,
  Modal,
  Table,
  Toast,
  ToastContainer,
  Stack,
} from "react-bootstrap";
import { AuthContext } from "../App";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "@blueprintjs/core";
import axios from "axios";
import { List } from "react-bootstrap-icons";
import StylizedBG from "../components/StylizedBG";

export default function ManageMembersDash() {
  const [defaultForm, setDefaultForm] = useState({
    divisions: [],
    rank: 5,
    userRbx: "",
    userDisc: "",
    id: 0,
  });
  const [lastQuery, setLastQuery] = useState({});
  const [userData, setUserData] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [addableDivisions, setAddableDivisions] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState("none");
  const [divQuery, setDivQuery] = useState([]);
  const [rankQuery, setRankQuery] = useState([]);
  const [queryType, setQueryType] = useState("none"); // none, div, rank
  const [searchQuery, setSearchQuery] = useState(); // rbxID, rbxUser, discID, discUser
  const [queryDisabled, setQueryDisabled] = useState(true);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [queryResults, setQueryResults] = useState([]);
  const [modifyForm, setModifyForm] = useState({
    divisions: [],
    rank: 5,
    userRbx: "",
    userDisc: "",
    id: 0,
  });
  const [addedDivs, setAddedDivs] = useState([]);
  const [addedDivsElements, setAddedDivsElements] = useState([]);
  const [divs, setDivs] = useState([]);
  const [formData, setFormData] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [rank, setRank] = useState(0);
  const [notification, setNotification] = useState();
  const [notifShow, setNotifShow] = useState(false);
  const [
    _,
    setAuthKey,
    authed,
    setAuthed,
    timeCreated,
    setTimeCreated,
    modalShow,
    setModalShow,
    modalData,
    setModalData,
  ] = useContext(AuthContext);
  const [currentModal, setCurrentModal] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const divisions = [
  //   "Security",
  //   "Research",
  //   "Hagilgulim",
  //   "Safety & Regulation",
  //   "External Affairs",
  //   "Logistics",
  // ];
  const authKey = localStorage.getItem("key");

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData, // shallow copy all previous state
      [name]: value, // update specific key/value
    }));
  };

  useEffect(() => {
    console.log(selectedQuery);
    setQueryDisabled(selectedQuery === "none" ? true : false);
    localStorage.setItem(
      "test",
      JSON.stringify({ divisions: [], rank: 0, userRbx: "", userDisc: "" })
    );
  }, [selectedQuery]);

  useEffect(() => {
    const config = {
      url: document.MAIN_URL+"/divisions",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
    };
    axios({
      url: document.MAIN_URL+"/getSelf",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
    }).then((selfData) => {
      console.log(selfData.data);
      setUserData(selfData.data);
      axios(config).then((res) => {
        const divisionsObject = {};
        res.data.map((division) => {
          divisionsObject[division.divisionName] = division;
        });
        setDivisions(divisionsObject);
        console.log(divisionsObject);
        const addable = [];
        Object.keys(selfData.data.divisions).forEach((division, i) => {
          console.log(Object.keys(selfData.data.divisions), division, i);
          const userRole = selfData.data.divisions[division];
          const divRole = divisionsObject[division].roles[Number(userRole)];
          if (
            divRole &&
            (divRole.canManage || divRole.canRank || divRole.superUser)
          ) {
            console.log("added", division);
            addable.push(division);
          }
        });
        setAddableDivisions(addable);
      });
    });
  }, []);

  const searchQueryChanged = (e) => {
    console.log("??");
    if (
      Number(e.target.value) &&
      (queryType === "rbxID" || queryType === "discID")
    ) {
      setSearchQuery(e.target.value);
    } else if (queryType === "rbxUser" || queryType === "discUser") {
      setSearchQuery(e.target.value);
    }
  };

  const queryTypeChanged = (e) => {
    setQueryType(e.target.id);
    setSearchQuery("");
    setSearchDisabled(!searchQuery === "none" ? true : false);
  };

  const selectedQueryChanged = (e) => {
    setSelectedQuery(e.target.id);
    setQueryDisabled(selectedQuery !== "none" ? false : true);
    console.log(queryDisabled, selectedQuery, e.target.id);
    setSelectedQuery(e.target.id);
    
  };

  const executeQuery = async (queryData) => {
    const config = {
      url: document.MAIN_URL+"/queryMembers",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: JSON.stringify(queryData),
    };
    setLastQuery(queryData);
    await axios(config).then((res) => {
      console.log(res);
      const elements = [];

      setQueryResults(res.data);
      console.log(queryResults, elements);
    });
  };

  const setRankElements = () => {
    const rQuery = [];
    for (let i = 0; i < 10; i++) {
      rQuery[i] = (
        <>
          {" "}
          <NavDropdown.Item
            href={"#" + (i + 1)}
            key={i}
            id={i + 1}
            onClick={selectedQueryChanged}
            value={"rank"}
          >
            {i + 1}
          </NavDropdown.Item>
        </>
      );
    }
    setRankQuery(rQuery);
  };

  const executeSearchQuery = (e) => {
    console.log(e.target.value);
    console.log(searchQuery);
    const queryData = {
      queryType: "search",
      queryTerm: queryType,
      query: searchQuery,
    };
    console.log(queryData);
    executeQuery(queryData);
  };

  const executeTypeQuery = (e) => {
   
    const queryData = {
      queryType: "enum",
      queryTerm: (Number(selectedQuery) && "rank") || "division",
      query: selectedQuery,
    };
    console.log(queryData);
    executeQuery(queryData);
  };

  const loadModifyModal = async (member) => {
    console.log(member);
    setAddedDivs([]);
    const endPoint = member.userData ? "roblox" : "discord";
    const config = {
      method: "POST",
      url: document.MAIN_URL+`/${endPoint}MemberExists`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: { id: endPoint === "roblox" ? member.userData.uid : member.id },
    };
    console.log(config); 

    await axios(config).then((res) => {
      console.log(res);
      const userDataNotFound = res.data["notFound"];

      if (userDataNotFound) {
        console.log("not found")
      } else {
        console.log("?!!")
        const operatorPermissions = JSON.parse(
          localStorage.getItem("permissions")
        );
        if (!operatorPermissions) return;
        setPermissions(operatorPermissions);
        const data = {
          divisions: res.data.divisions,
          rank: res.data.rank,
          userRbx: res.data.userData.name,
          userDisc: "",
          id: res.data.docId,
        };
        console.log("modifyForm", data);
        setModifyForm(data);
        const ranks = [];
        for (let i = 1; i <= 10; i++) {
          console.log(
            i >= operatorPermissions.maxRank,
            data.rank >= operatorPermissions.maxRank
          );
          ranks[i] = (
            <>
              <option
                key={i}
                disabled={
                  i >= operatorPermissions.maxRank ||
                  Number(data.rank) >= Number(operatorPermissions.maxRank)
                }
              >
                {i}
              </option>
            </>
          );
        }
        setRanks(ranks);
        console.log("ranks", ranks);
        const divs = [];
        Object.keys(data.divisions).forEach((division, i) => {
          divs[i] = (
            <>
              <tr key={division}>
                <td>
                  {operatorPermissions.superAdmin ||
                  operatorPermissions.divAdmin[division]
                    ? // <Button size={"sm"}>
                      //   <span className="spec-disp">X</span>
                      // </Button>
                      null
                    : null}
                </td>
                <td>
                  {" "}
                  <span className="view-roles">{division}</span>
                </td>
                <td>
                  {" "}
                  <span className="view-roles">
                    &nbsp;{data.divisions[division]}
                  </span>
                </td>
              </tr>
            </>
          );
        }); 
        console.log("???????")
        setDivs(divs);
        handleShow();
        setShow(true)
        setRank(data.rank);
      }
    }).catch(err=>{console.log(err)});
  };

  useEffect(() => {
     executeTypeQuery();
  }, [selectedQuery]);

  const modifyMember = async (e) => {

    console.log(e.target.name)
    const config = {
      method: "POST",
      url: document.MAIN_URL+`/modifyMember`,
      headers: {
        "Content-Type": "application/json",
        Authorization: authKey,
      },
      data: {
        ...modifyForm, exile : e.target.name === "exile" ? true : false, rank: rank,
      },
    };
    console.log(config.data);
    await axios(config).then((res) => {
      if (res.data.success) {
        setNotification(res.data.message);
        setNotifShow(true);
        handleClose();
        executeQuery(lastQuery);
      } else {
        setNotification(res.data.message);
        setNotifShow(true);
      }
    });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <td
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </td>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    const elements = addedDivs.map((div, i) => {
    return   ( <>
        <tr key={div}>
          <td colSpan={1}>
            <Button
              className="Button noround btn btn-danger-outline"
              size={"sm"}
              onClick={(e) => {
                setAddedDivs((oldData) => {
                  console.log(addedDivs, oldData);
                  oldData.splice(i, 1);
                  console.log(addedDivs, oldData);
                  return [...oldData];
                });
                setModifyForm((oldData) => {
                  console.log(addedDivs, oldData);
                  delete oldData.divisions[div];
                  console.log(addedDivs, oldData);
                  return {...oldData};
                });
              }}
            >
              {"x"}
            </Button>
          </td>
          <td>
            <span className="data-spec-1">{div}</span>
          </td>
          <td>
            <span className="view-roles">&nbsp;{"0"}</span>
          </td>
        </tr>
      </>)
    });
    console.log("RAN SETADDEDDIVSELEMENTS",elements);
    setAddedDivsElements(elements);
  }, [modifyForm,addedDivs]);

  return (
    <>
          <ToastContainer
        className="p-3"
        position={"top-end"}
        style={{ zIndex: 9999999999999999 }}
      >
        <Toast
          onClose={() => setNotifShow(false)}
          show={notifShow}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Action Notification</strong>
            <small style={{ fontVariant: "small-caps" }}>kaleida</small>
          </Toast.Header>
          <Toast.Body>{notification}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{modifyForm.userRbx}</h4>
          <span className="data-spec">clearance</span>
          <Form.Select
            size="sm"
            value={rank}
            name="rank"
            onChange={(e) => {
              setRank(e.target.value);
            }}
          >
            {ranks}
          </Form.Select>
          <br />
          <Stack direction="horizontal" gap={3}>
          <span className="data-spec">divisions</span>
          <Dropdown
            onSelect={(e) => {
              const newDivs = modifyForm.divisions;
              newDivs[e] = 0;
              setModifyForm((oldData) => {
                return { ...oldData, divisions: newDivs };
              });
              setAddedDivs((oldData) => {
                if (oldData.includes(e)) return oldData;
                oldData.push(e);
                console.log(oldData);
                return oldData;
              });
            }}
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Button
                className="Button btn btn-danger-outline"
                size={"sm"}
                style={{filter:'invert(1)'}}
              >
                add..
              </Button>
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
              {Object.values(addableDivisions).map((div, i) => {
                console.log(modifyForm);
                return (
                  <Dropdown.Item
                    key={div + i}
                    eventKey={div}
                    disabled={(modifyForm.divisions[div] || addedDivs[div])}
                  >
                    <span className="find-user-property-3">{div}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          </Stack>
          <Table striped bordered hover size="sm" className="table-variant-1">
            <thead>
              <tr>
                <th></th>
                <th>Division</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {divs}
              {addedDivsElements}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          {permissions.canManage ? (
            <Button
              name = "exile"
              variant="outline-danger"
              onClick={(e) => {
                setModifyForm(form=>{return {...form,exile:true}});
                modifyMember(e);
              }}
            >
              Exile Member
            </Button>
          ) : null}
          <Button onClick={handleClose}>Discard Changes</Button>
          <Button onClick={modifyMember} variant="outline-success" name = 'confirm'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
             <StylizedBG> 
      <Container className="member-manage backblur">
        <br />
        <Navbar bg="light" expand="lg" className="mm-nb">
          <Container fluid className="manage-members">
            <Navbar.Brand href="#">query</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <NavDropdown title="clearance" id="navbarScrollingDropdown">
                  {!rankQuery.length ? setRankElements() : rankQuery}
                </NavDropdown>
                <NavDropdown title="division" id="navbarScrollingDropdown">
                  {!divQuery.length && divisions
                    ? Object.keys(divisions).map((div, i) => {
                        divQuery[i] = (
                          <>
                            {" "}
                            <NavDropdown.Item
                              href={"#" + div}
                              key={i}
                              id={div}
                              value="div"
                              onClick={selectedQueryChanged}
                            >
                              {div}
                            </NavDropdown.Item>
                          </>
                        );
                      })
                    : divQuery}
                </NavDropdown>
                <Nav.Link
                  href="#"
                  disabled={true}
             
                >
                  <span
                    className={(!queryDisabled && "find-user-property-3") || ""}
                  >
                    {!queryDisabled ? (
                      <>
                        {(Number(selectedQuery) && "rank") || "division"} :{" "}
                        {selectedQuery}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </Nav.Link>
              </Nav>
              <Navbar.Brand href="#">search members</Navbar.Brand>
              <DropdownButton
                variant="dark"
                title={
                  queryType === "none"
                    ? "Select Query Type"
                    : (queryType === "rbxID" && "Roblox User ID") ||
                      (queryType === "rbxUser" && "Roblox Username") ||
                      (queryType === "discUser" && "Discord Username") ||
                      (queryType === "discID" && "Discord Id")
                }
                id="input-group-dropdown-1"
              >
                <Dropdown.Item href="#" id="rbxID" onClick={queryTypeChanged}>
                  Roblox User ID
                </Dropdown.Item>

                <Dropdown.Item href="#" id="rbxUser" onClick={queryTypeChanged}>
                  Roblox Username
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  id="discUser"
                  onClick={queryTypeChanged}
                >
                  Discord Username
                </Dropdown.Item>
                <Dropdown.Item href="#" id="discID" onClick={queryTypeChanged}>
                  Discord Id
                </Dropdown.Item>
              </DropdownButton>
              <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  onChange={searchQueryChanged}
                  value={searchQuery}
                  disabled={searchDisabled}
                />
                <Button
                  variant="outline-success"
                  value="search"
                  onClick={executeSearchQuery}
                >
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Table striped bordered hover size="sm" className="table-variant-1">
          <thead>
            <tr>
              <th>Member</th>
              <th>Role</th>
              <th>Global Clearance</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {queryResults.map((member, i) => {
              return (
                <>
                  <tr key={i}>
                    <td>
                      <span className="member-name mm-lg">
                        {member.avatarURL ? (
                          <span className="d-inline-block view-roles">
                            <Image
                              src={member.avatarURL}
                              fluid
                              className="query-image"
                            />
                          </span>
                        ) : null}
                        {
                          member.userData
                            ? member.userData.name
                            : member.username // `clearance: ${member.rank}`
                        }
                      </span>
                    </td>
                    <td>
                      {member.divisions ? (
                        <>
                          {
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  <ListGroup>
                                    {Object.keys(member.divisions).map(
                                      (division) => (
                                        <ListGroup.Item className="raro-data-list">
                                          {division} :{" "}
                                          {member.divisions[division]}
                                        </ListGroup.Item>
                                      )
                                    )}
                                  </ListGroup>
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block view-roles">
                                view divisions
                              </span>
                            </OverlayTrigger>
                          }
                        </>
                      ) : (
                        <>
                          {
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  <ListGroup>
                                    {member.roles.map((role) => {
                                      if (
                                        role !== "@everyone" &&
                                        role !== "Verified"
                                      ) {
                                        return (
                                          <ListGroup.Item className="raro-data-list">
                                            {role}
                                          </ListGroup.Item>
                                        );
                                      }
                                    })}
                                  </ListGroup>
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block view-roles">
                                view roles
                              </span>
                            </OverlayTrigger>
                          }
                        </>
                      )}
                    </td>
                    <td>
                      <span className={"member-cl-im-data"}>
                        {member.divisions ? (
                          <span className="d-inline-block view-roles">
                            clearance:{" "}
                            <span className="data-spec-1">
                              {member.rank}
                            </span>
                          </span>
                        ) : null}
                      </span>
                    </td>
                    <td>
                      <Button
                        className="button modify-profile"
                        onClick={(e) => loadModifyModal(member)}
                      >
                        modify profile
                      </Button>
                    </td>
                  </tr>
                </>
              );
            })}
            <tr key={1}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Container>
      </StylizedBG>
    </>
  );
}
